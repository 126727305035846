import React from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import styles from './ProfileCard.module.css';

interface props{
    name?: string,
    surname?: string,
    username?: string,
    email?: string,
    phoneNumber?: string,
}

export default function ProfileCard({name, surname, username, email, phoneNumber}: props) {    
    return(
        <Card>
            <CardHeader
                 title={
                    <Typography variant='h5' color='secondary'>
                        Datos de la cuenta
                    </Typography>}
            />
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item md={4} className={styles.itemLeft}>Nombre</Grid>
                    <Grid item md={3} className={styles.itemRight}>{name}</Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item md={4} className={styles.itemLeft}>Apellido</Grid>
                    <Grid item md={3} className={styles.itemRight}>{surname}</Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item md={4} className={styles.itemLeft}>Nombre de usuario</Grid>
                    <Grid item md={3} className={styles.itemRight}>{username}</Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item md={4} className={styles.itemLeft}>Correo electronico</Grid>
                    <Grid item md={3} className={styles.itemRight}>{email}</Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item md={4} className={styles.itemLeft}>Numero de Telefono</Grid>
                    <Grid item md={3} className={styles.itemRight}>{phoneNumber}</Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}