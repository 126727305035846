import {
  GET_CASES_FAILURE,
  GET_CASE_BY_ID_FAILURE,
  UPDATE_ATTENTIONS_FAILURE,
  POST_ATTENTION_FAILURE,
  GET_PROVISIONS_FAILURE,
  GET_TENANT_FAILURE,
  POST_LOGIN_FAILURE,
  GET_USER_DATA_FAILURE,
  POST_LOGOUT_FAILURE,
  POST_RESTORE_PASSWORD_FAILURE,
  PATCH_ATTENTION_FAILURE,
  POST_EVOLUTION_FORM_FAILURE,
  POST_GFORM_EVOLUTION_FAILURE,
  POST_EVOLUTION_FAILURE,
  GET_ATTENTIONS_BY_QPS_FAILURE,
  UPDATE_EVOLUTIONS_FAILURE,
  GET_EVOLUTION_PHOTO_FAILURE,
  GET_CATEGORIES_FAILURE,
} from "../action_types";

export const getCasesFailure = () => ({
  type: GET_CASES_FAILURE,
});

export const getCaseByIdFailure = () => ({
  type: GET_CASE_BY_ID_FAILURE,
});

export const updateAttentionFailure = () => ({
  type: UPDATE_ATTENTIONS_FAILURE,
});

export const postAttentionFailure = () => ({
  type: POST_ATTENTION_FAILURE,
});

export const getProvisionsFailure = () => ({
  type: GET_PROVISIONS_FAILURE,
});

export const getTenantFailure = () => ({
  type: GET_TENANT_FAILURE,
});

export const postLoginFailure = () => ({
  type: POST_LOGIN_FAILURE,
});

export const getUserDataFailure = () => ({
  type: GET_USER_DATA_FAILURE,
});

export const postLogoutFailure = () => ({
  type: POST_LOGOUT_FAILURE,
});

export const postRestorePasswordFailure = () => ({
  type: POST_RESTORE_PASSWORD_FAILURE,
});

export const patchAttentionFailure = () => ({
  type: PATCH_ATTENTION_FAILURE,
});

export const postEvolutionFormFailure = () => ({
  type: POST_EVOLUTION_FORM_FAILURE,
});

export const postGFormEvolutionFailure = () => ({
  type: POST_GFORM_EVOLUTION_FAILURE,
});

export const postEvolutionFailure = () => ({
  type: POST_EVOLUTION_FAILURE,
});

export const getAttentionsByQPSFailure = () => ({
  type: GET_ATTENTIONS_BY_QPS_FAILURE,
});

export const updateEvolutionsFailure = () => ({
  type: UPDATE_EVOLUTIONS_FAILURE,
});

export const getEvolutionPhotoFailure = () => ({
  type: GET_EVOLUTION_PHOTO_FAILURE,
});

export const getCategoriesFailure = () => ({
  type: GET_CATEGORIES_FAILURE,
});
