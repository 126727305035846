import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Grid from '@material-ui/core/Grid';

import NewAttentionDialog from '../AttentionDialogs/NewAttentionDialog';
import { ArrowRight } from '@material-ui/icons';
import { USER } from '../../../../shared/utils/model/user';
import { AGREEMENT } from '../../../../shared/utils/model/agreement';
import { RESTRICTION } from '../../../../shared/utils/model/restriction';


/*
    CHECKEAR USUARIO CON MAS DE UN AGREEMENT
    EL TITULO SE PUEDE LLEGAR A REPETIR
*/

interface props{
    user: USER,
    agreements: {[key: string]: AGREEMENT},
    restriction: RESTRICTION,
}
const Agreements = ({user, agreements, restriction}:props) => {
    const [open, setOpen] = React.useState(false);
    const [actualAgreement, setActualAgreement] = useState('');


    const handleClickOpen = (agreementId:string) => {
        setOpen(true);
        setActualAgreement(agreementId)
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    
    return(
        <>
            <Grid container spacing={2}>
                <Grid item sm={12} md={12}>
                    <h4 style={{margin: '0px', color: '#8cb1a2'}}>
                        Acuerdos Prestacionales
                    </h4>
                </Grid>
                {
                    Object.keys(agreements).map((key: string, i: any) => {
                        return(
                            <Grid container key={i}>
                                <Grid item md={1}><ArrowRight color='primary'/></Grid>
                                <Grid item md={4} style={{color: '#9e9e9e'}}>#{agreements[key].id}</Grid>
                                <Grid item md={4} style={{color: '#9e9e9e'}}>{agreements[key].provider.name}</Grid>
                                
                                {
                                    !!(user?.data?.corporate) &&
                                    <Grid item md={2}>
                                        <IconButton 
                                            style={{padding: '0px'}}
                                            onClick={() => handleClickOpen(agreements[key].id)}
                                        >
                                            <AddCircleIcon 
                                                color='primary'
                                            />
                                        </IconButton>
                                    </Grid>
                                }
                            </Grid>
                        )
                    })
                }
            </Grid>
            <NewAttentionDialog
                agreementId={actualAgreement}
                restriction={restriction}
                _open={open}
                handleClose={handleClose}
            />
        </>
    );
}

const mapStatesToProps = (state: any, props: any) => {
    const user: USER = state.user;
    return {
        user
    };
}
 
 export default connect(mapStatesToProps, null)(Agreements);