import React, { useState } from 'react';

import FlexView from 'react-flexview';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { postRestorePasswordClose } from '../../../../redux/actions/closeActions';
import styles from './PasswordRestore.module.css';
import { postRestorePassword, POST_PASS_RESTORE_BODY } from '../../../../redux/actions/userActions';
import WaitingAnimation from '../../../../shared/modules/WaitingAnimation/WaitingAnimation';
import { connect, useDispatch } from 'react-redux';
import { requestStateSelector } from '../../../../redux/reducers/requestStateReducer';

interface props{
  tenantId: number,
  requestState: string,
  handleForgot: any,
  postRestorePasswordClose: any,
  postRestorePassword: any,
}

const PasswordRestore = ({
  tenantId, 
  requestState, 
  handleForgot, 
  postRestorePasswordClose,
  postRestorePassword
}: props) => {
    const [emailRestore, setEmailRestore] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [disabledButton, setDisabledButton] = useState<boolean>(false);
    const [error, setError] = useState(false);

    const dispatch = useDispatch();

    const handleRestoreSubmit = () => {
      //setDisabledButton(true);
      if(validateEmailInput()) {
        const body: POST_PASS_RESTORE_BODY = {
          email: emailRestore,
          applicationId: '3',
          tenantId: tenantId,
        }
        postRestorePassword(body);
      } else {
        console.error("Email Restore incorrecto");
        setError(true);
      }
      //setDisabledButton(false);
    }

    const handleRestoreChange = (event: { target: { value: any; }; }) => {
      setEmailRestore(event.target.value);
    }

    const handleSnackbarClose = () => {
      dispatch(postRestorePasswordClose());
    }

    const validateEmailInput = () => {
      return (new RegExp(
        // tslint:disable-next-line: max-line-length
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      ).test(emailRestore))
    }

    return(
      (requestState != 'SUCCESS' ) ?
        <FlexView hAlignContent='center' vAlignContent='center' grow={2}>
            <div className={styles.loginCard}>
              <Card>
                <CardHeader
                  title={<Typography color='secondary' variant='h5'>Restaurar Cuenta</Typography>}
                  subheader={'Para restaurar la contraseña de su cuenta, indique su casilla de correo a continuación:'}
                />
                <CardContent>
                  <div>
                    <FormControl style={{ width: '100%' }}>
                      <InputLabel error={error} htmlFor="standard-adornment-username">Ingrese su correo electronico</InputLabel>
                      <Input
                        error={error}
                        name="emailRestore"
                        color='primary'
                        id="standard-adornment-username"
                        type='text'
                        value={emailRestore}
                        onChange={handleRestoreChange}
                      />
                    </FormControl>
                  </div>
                  <FlexView hAlignContent='right' className={styles.buttonCont}>
                    <Button color='secondary' onClick={handleForgot}>Cancelar</Button>
                    <Button variant='contained' color='primary'
                      onClick={handleRestoreSubmit}
                      className={styles.restoreButton}
                      disabled={requestState == 'REQUEST'}
                    >
                      {
                        (requestState != 'REQUEST')
                          ? 'Restaurar Contraseña' 
                          : <WaitingAnimation width={20} height={20} />
                      }
                    </Button>
                  </FlexView>
                </CardContent>
              </Card>
            </div>
          <Snackbar open={requestState=='FAILURE'} autoHideDuration={4000} onClose={handleSnackbarClose}>
            <Alert onClose={handleSnackbarClose} severity="error">
              Ha ocurrido un error
            </Alert>
          </Snackbar>  
        </FlexView>
        :
        <FlexView hAlignContent='center' vAlignContent='center' grow={2}>
            <div className={styles.loginCard}>
              <Card>
                <CardHeader 
                  title={<Typography color='secondary' variant='h5'>Se ha enviado un mail a su casilla de correo</Typography>}
                />
                <CardContent>
                  <Typography variant='body2' className={styles.infoText}>
                  Verifique su casilla de correo para continuar con el proceso de restauración de contraseña. 
                  </Typography>
                  <Typography variant='body2' className={styles.infoText}>
                  Si no lo recibe de inmediato, verifique su carpeta de correo no deseado.
                  </Typography>
                </CardContent>
              </Card>
            </div>
        </FlexView>
    ) 
}

const mapDispatchToProps = {
  postRestorePasswordClose,
  postRestorePassword,
}

const mapStatesToProps = (state: any, props: any) => {
  const requestState = requestStateSelector('POST_RESTORE_PASSWORD_', state);
  return{
    requestState
  }
}

export default connect(mapStatesToProps, mapDispatchToProps)(PasswordRestore);