//file for merge all reducers before send to the store
import { combineReducers } from 'redux';
import { LOG_OUT, SET_HEADER } from "../action_types";
import attentionsReducer from './attentionsReducer';
import evolutionsReducer from "./evolutionsReducer";
import caseReducer from "./casesReducer";
import categoriesReducer from "./categoriesReducer";
import provisionsReducer from "./provisionsReducer";
import userReducer from "./userReducer";
import actualCaseReducer from "./actualCaseReducer";
import headerReducer from "./headerReducer";
import requestStateReducer from "./requestStateReducer";

let userLocalStorage: string = "";
let _initialState = {};
if (!!localStorage.getItem("user")) {
  userLocalStorage = localStorage.getItem("user") as string;
  const _userLocalStorage = JSON.parse(userLocalStorage);
  _initialState = { user: _userLocalStorage };
}
export const initialState = { ..._initialState };

const appReducer = combineReducers({
  user: userReducer,
  cases: caseReducer,
  attentions: attentionsReducer,
  evolutions: evolutionsReducer,
  categories: categoriesReducer,
  provisions: provisionsReducer,
  actualCase: actualCaseReducer,
  header: headerReducer,
  requestState: requestStateReducer,
});

const rootReducer = (state: any, action: any) => {
  //Return state to initialState
  if (action.type === LOG_OUT) state = {};

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;