
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { setHeader } from '../../redux/actions/headerActions';
import { USER } from '../../shared/utils/model/user';
import './Home.css';
import TerveyText from '../../assets/Tervey/text.png';
import TerveyLogo from '../../assets/Tervey/logo.png'
import SimfinixText from '../../assets/Simfinix/text.png';
import AttentionsCalendar from './components/AttentionsCalendar/AttentionsCalendar';

interface props{
  setHeader: any,
}

const Home = ({setHeader}: props) => {

  useEffect(() => {
    setHeader('Home');
  }, [])
  
  return (
    <div>
      <Grid container justify='center'>
        <Grid item md={10}>
          <AttentionsCalendar/>
        </Grid>
      </Grid>
    </div>
  );
}

const mapDispatchToProps = {
  setHeader,
}

// const mapStatesToProps = (state: any, props: any) => {
// }

export default connect(null, mapDispatchToProps)(Home);