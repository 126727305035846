import axios from "axios";
import {
  postEvolutionEndPoint,
  postEvolutionFormEndPoint,
  getEvolutionMediaEndPoint,
  postEvolutionMediaEndPoint,
  getCaseEvolutionsEndPoint,
  getCategoriesEndPoint,
  getEvolutionCountEndPoint,
} from "../../shared/utils/apis";
import {
  postEvolutionFailure,
  postEvolutionFormFailure,
  updateEvolutionsFailure,
  getEvolutionPhotoFailure,
  getCategoriesFailure,
} from "./failureActions";
import {
  postEvolutionFormRequest,
  postEvolutionRequest,
  updateEvolutionsRequest,
  getEvolutionPhotoRequest,
  getCategoriesRequest,
} from "./requestActions";
import {
  postEvolutionFormSuccess,
  postEvolutionSuccess,
  updateEvolutionsSuccess,
  getEvolutionPhotoSuccess,
  getCategoriesSuccess,
} from "./successActions";

import {
  UPDATE_EVOLUTIONS,
  SET_EVOLUTION_MEDIA,
  SET_CATEGORIES,
} from "../action_types";

export const postEvolutionForm =
  (attentionId: number) => async (dispatch: any) => {
    dispatch(postEvolutionFormRequest());
    try {
      const response = await axios.post(postEvolutionFormEndPoint(), {
        attentionId: attentionId,
        coordinates: {
          accuracy: 0,
          altitude: 0,
          heading: 0,
          latitude: 0,
          longitude: 0,
          speed: 0,
        },
      });
      dispatch(postEvolutionFormSuccess());
      return response.data;
    } catch (error: any) {
      console.error("Could not post evolution form. ", error.message);
      dispatch(postEvolutionFormFailure());
    }
  };

// NO BORRAR, ES PARA UN FUTURO LEJANO
// export const postGFormEvolution = (token: string) => async (dispatch:any) => {
//     dispatch(postGFormEvolutionRequest());
//     try{
//         const response = await axios.post(
//             postEvolutionGCEndPoint(),
//             {
//                 formToken: token,
//             },
//             {
//                 headers:{
//                     'Access-Control-Allow-Origin' : '*',
//                     'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
//                     'Referrer-Policy': 'no-referrer-when-downgrade',
//                 }
//             }
//         );
//         dispatch(postGFormEvolutionSuccess());
//         return response.data;
//     }
//     catch(error){
//         console.error('Could not post GFORM evolution. ',error.message);
//         dispatch(postGFormEvolutionFailure());
//     }
// }
// NO BORRAR, ES PARA UN FUTURO LEJANO

export const postEvolution =
  (
    attentionId: any,
    categoryId: any,
    toDate: any,
    description: any,
    evolutionType: number,
    photoDataUrl: string,
    formType: string = "default"
  ) =>
  async (dispatch: any) => {
    dispatch(postEvolutionRequest());
    try {
      const mediaUrls = photoDataUrl ? [photoDataUrl.replace(/\s/g, "")] : null;
      const responsePost = await axios.post(postEvolutionEndPoint(), {
        attentionId,
        categoryId,
        mediaUrls,
        text: description,
        type: evolutionType,
        date: toDate,
        coords: {
          accuracy: 0,
          altitude: 0,
          heading: 0,
          latitude: 0,
          longitude: 0,
          speed: 0,
        },
        formType,
      });
      dispatch(postEvolutionSuccess());
    } catch (error: any) {
      console.error("Could not post evolution. ", error.message);
      dispatch(postEvolutionFailure());
    }
  };

export const postMedia =
  ({
    attentionId,
    mediaUUID,
    url,
  }: {
    attentionId: number;
    mediaUUID: string;
    url: string;
  }) =>
  async () => {
    try {
      await axios.post(
        postEvolutionMediaEndPoint(),
        {
          attentionId,
          uuid: mediaUUID,
          mediaUrls: [url],
        },
        { timeout: 5000 }
      );
    } catch (error) {
      console.info("Error setting evolution");
      console.info(error);
    }
  };

export const updateEvolutions = (caseId: number) => async (dispatch: any) => {
  dispatch(updateEvolutionsRequest());
  try {
    const response = await axios.get(getCaseEvolutionsEndPoint(caseId), {
      timeout: 30000,
    });
    const data = response?.data?.data;
    dispatch({
      type: UPDATE_EVOLUTIONS,
      payload: data,
    });
    dispatch(updateEvolutionsSuccess());
    return Promise.resolve("Success");
  } catch (error) {
    console.info("Error updating evolutions data");
    console.info(error);
    dispatch(updateEvolutionsFailure());
  }
};

export const getEvolutionPhoto =
  (evolutionId: number) => async (dispatch: any) => {
    dispatch(getEvolutionPhotoRequest());
    try {
      const response = await axios.get(getEvolutionMediaEndPoint(evolutionId), {
        timeout: 5000,
      });
      const data = response?.data?.data;
      dispatch({
        type: SET_EVOLUTION_MEDIA,
        payload: data,
      });
      dispatch(getEvolutionPhotoSuccess());
    } catch (error) {
      dispatch(getEvolutionPhotoFailure());
      console.info("Error getting evol photo");
      console.info(error);
    }
  };

export const getCategories = (attentionId: string) => async (dispatch: any) => {
  try {
    dispatch(getCategoriesRequest());

    let searchParams = new URLSearchParams();

    searchParams.set("attentionsIds", attentionId);
    searchParams.set("categoriesIds", "21");

    const { data: hasMedicalEvolutions } = await axios.get(
      `${getEvolutionCountEndPoint(searchParams)}`,
      {
        timeout: 5000,
      }
    );

    let urlSearchParams = new URLSearchParams();

    urlSearchParams.set("active", "1");

    if (hasMedicalEvolutions === 0) {
      urlSearchParams.set("description", "Evolución médica");
    }

    const { data: categories } = await axios.get(
      getCategoriesEndPoint(urlSearchParams),
      {
        timeout: 5000,
      }
    );

    dispatch({
      type: SET_CATEGORIES,
      payload: categories?.data,
    });

    dispatch(getCategoriesSuccess());
  } catch (error) {
    dispatch(getCategoriesFailure());
    console.info("Error getting categories");
    console.info(error);
  }
};
