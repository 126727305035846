import {
  GET_CASES_REQUEST,
  GET_CASE_BY_ID_REQUEST,
  UPDATE_ATTENTIONS_REQUEST,
  POST_ATTENTION_REQUEST,
  GET_PROVISIONS_REQUEST,
  GET_TENANT_REQUEST,
  POST_LOGIN_REQUEST,
  GET_USER_DATA_REQUEST,
  POST_LOGOUT_REQUEST,
  POST_RESTORE_PASSWORD_REQUEST,
  PATCH_ATTENTION_REQUEST,
  POST_EVOLUTION_FORM_REQUEST,
  POST_GFORM_EVOLUTION_REQUEST,
  POST_EVOLUTION_REQUEST,
  GET_ATTENTIONS_BY_QPS_REQUEST,
  UPDATE_EVOLUTIONS_REQUEST,
  GET_EVOLUTION_PHOTO_REQUEST,
  GET_CATEGORIES_REQUEST,
} from "../action_types";

export const getCasesRequest = () => ({
  type: GET_CASES_REQUEST,
});

export const getCaseByIdRequest = () => ({
  type: GET_CASE_BY_ID_REQUEST,
});

export const updateAttentionRequest = () => ({
  type: UPDATE_ATTENTIONS_REQUEST,
});

export const postAttentionRequest = () => ({
  type: POST_ATTENTION_REQUEST,
});

export const getProvisionsRequest = () => ({
  type: GET_PROVISIONS_REQUEST,
});

export const getTenantRequest = () => ({
  type: GET_TENANT_REQUEST,
});

export const postLoginRequest = () => ({
  type: POST_LOGIN_REQUEST,
});

export const getUserDataRequest = () => ({
  type: GET_USER_DATA_REQUEST,
});

export const postLogouRequest = () => ({
  type: POST_LOGOUT_REQUEST,
});

export const postRestorePasswordRequest = () => ({
  type: POST_RESTORE_PASSWORD_REQUEST,
});

export const patchAttentionRequest = () => ({
  type: PATCH_ATTENTION_REQUEST,
});

export const postEvolutionFormRequest = () => ({
  type: POST_EVOLUTION_FORM_REQUEST,
});
export const postGFormEvolutionRequest = () => ({
  type: POST_GFORM_EVOLUTION_REQUEST,
});

export const postEvolutionRequest = () => ({
  type: POST_EVOLUTION_REQUEST,
});

export const getAttentionsByQPSRequest = () => ({
  type: GET_ATTENTIONS_BY_QPS_REQUEST,
});

export const updateEvolutionsRequest = () => ({
  type: UPDATE_EVOLUTIONS_REQUEST,
});

export const getEvolutionPhotoRequest = () => ({
  type: GET_EVOLUTION_PHOTO_REQUEST,
});

export const getCategoriesRequest = () => ({
  type: GET_CATEGORIES_REQUEST,
});