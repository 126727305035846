import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import { TableRow, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import styles from './AtentionsList.module.css';
import { ATTENTION } from '../../../../shared/utils/model/attention';
import WaitingAnimation from '../../../../shared/modules/WaitingAnimation/WaitingAnimation';
import AttentionRow from '../AttentionRow/AttentionRow';
import { requestStateSelector } from '../../../../redux/reducers/requestStateReducer';



const Headers = [
    'Entrada / Salida',
    'Especialidad',
    'Estado',
    'Evolucionar'
]

// agendada -> En Curso -> Realizada - No Realizada
// Verde -> Naranja -> Azul -> gris - No Realizada
const color = [
    'white', // no hay id 0 en el estado
    '#a7c6b9',
    '#5FE8D4',
    '#61F27E',
    '#E89A5F',
    '#B85FE8',
    '#F0E662',
    '#D7B1F2',
    '#7C5DE3',
    '#E6684C'
]

// esa: export const ATTENTIONS_ = {
    //     [AttentionStates.AGENDADA]: '#A7C6B9',
    //     [AttentionStates.REALIZADA]: '#61F27E',
    //     [AttentionStates.EN_CURSO]: '#5FE8D4',
    //     [AttentionStates.NO_REALIZADA]: '#E89A5F',
    //     [AttentionStates.INFORMADA]: '#7C5DE3',
    //     [AttentionStates.FACTURABLE]: '#B85FE8',
    //     [AttentionStates.EN_PROCESO]: '#D7B1F2',
    //     [AttentionStates.NO_INFORMADA]: '#E6684C',
    //     [AttentionStates.DEBITADA]: '#F0E662',
    //  }
    
interface props {
    attentions: {[key: string]: ATTENTION},
    requestState: string,
}

const AtentionsList = ({ attentions, requestState }:props) => {

    return (
        <>
            <Card>
                <CardHeader
                    style={{textAlign: 'center'}}
                    title={
                        <Typography variant='h6' color='secondary'>
                            Lista de Atenciones
                        </Typography>}
                />
                <CardContent>
                    {requestState != 'REQUEST' ?
                        <Paper elevation={3}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                        {
                                            Headers.map((header, i) => {
                                                return(
                                                    <TableCell key={i}>
                                                        <b>{header}</b> 
                                                    </TableCell>
                                                );
                                            })
                                        }
                                        </TableRow>
                                    </TableHead>
                                    {(Object.keys(attentions).length != 0)?
                                        <TableBody>
                                        {/* Informacion de la lista */}
                                        {
                                            Object.keys(attentions).map((key: string, i: any) => {
                                                return( <AttentionRow key={i} attention={attentions[key]}/>)
                                            })
                                        }
                                        </TableBody>
                                        :null
                                    }
                                </Table>
                            </TableContainer>
                            {(Object.keys(attentions).length == 0)? 
                                <Grid container justify='center' alignItems='center' className={styles.noAttCont}>
                                    <Grid item className={styles.noAttText}><b>No se han encontrado atenciones</b></Grid>
                                </Grid>
                                : null
                            }
                        </Paper> :
                        <WaitingAnimation 
                        height={75}
                        width={75}
                        />    
                    }
                </CardContent>
            </Card>
        </>
    );
}


const mapDispatchToProps = null;

const mapStatesToProps = (state: any, props: any) => {
   
    const attentions: {[key: string]: ATTENTION} = state.attentions;
    const requestState = requestStateSelector('UPDATE_ATTENTIONS_', state);
    
    return {
        attentions,
        requestState,
    };
}
 
 export default connect(mapStatesToProps, mapDispatchToProps)(AtentionsList);
