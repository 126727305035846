import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FileLoader from "../FileLoader/FileLoader";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import WizardJsonForm from "../../../../components/WizardJsonForm";

interface props {
  formData: any;
  open: boolean;
  handleClose: (...args: any) => any;
  submitEvolution: (...args: any) => any;
  postMedia?: () => void;
  //postGFormEvolution: any,
  media?: any;
  setMedia?: (value: any) => void;
}

const GFormDialog = ({
  formData,
  open,
  handleClose,
  submitEvolution,
  media,
  setMedia,
  postMedia,
}: props) => {
  const [failureAlert, setFailureAlert] = useState(false);

  const handleCloseButton = () => {
    handleClose();
  };

  // TODO: tech-debt add a better way to build this string
  const generateEvolution = (values: any) => {
    let titles = Object.keys(values).map((key) => values[key].title);
    let answers = Object.keys(values).map((key) => values[key].value);

    let description = "";
    let evolutionType = 1;

    for (let i = 0; i < titles.length; i++) {
      const title = titles[i];
      const value = answers[i];

      if (!title || !value) continue;

      switch (title) {
        case "FORM_TOKEN":
          break;
        case "Marca temporal":
          break;
        case "Informar al financiador":
          if (value === "Si") {
            if (evolutionType < 4) {
              evolutionType = 4;
            }
          } else if (value === "No") {
            if (evolutionType < 4) {
              evolutionType = 1;
            }
          }
          description += title + ": " + value + "\n";
          break;
        case "Es paciente en condición de alta?":
          if (value === "Si") {
            evolutionType = 10;
          } else if (value === "No") {
            evolutionType = 1;
          }
          description += title + ": " + value + "\n";
          break;
        default:
          const titleFirstCharacter = title.charAt(0);
          const titleWithoutFirstCharacter = title.slice(1);

          switch (titleFirstCharacter) {
            case ".":
              description += "";
              break;
            case "+":
              if (value === "Si") {
                if (evolutionType < 4) {
                  evolutionType = 4;
                }
              } else if (value === "No") {
                if (evolutionType < 4) {
                  evolutionType = 1;
                }
              }
              description += titleWithoutFirstCharacter + ": ";
              break;
            case "|":
              if (value === "Si") {
                evolutionType = 10;
              } else if (value === "No") {
                evolutionType = 1;
              }
              description += titleWithoutFirstCharacter + ": ";
              break;
            case ">":
              description += titleWithoutFirstCharacter + ": ";
              break;
            default:
              description += title + ": ";
              break;
          }

          description += value;

          switch (titleFirstCharacter) {
            case ">":
              description += "\n\n";
              break;
            default:
              description += "\n";
              break;
          }
          break;
      }
    }

    return {
      description,
      evolutionType,
    };
  };

  const handleSubmit = async (evolutionType: any, values: any) => {
    if (Object.keys(values).length === 0 && !media) {
      setFailureAlert(true);
    } else {
      try {
        if (typeof submitEvolution === "function") {
          await submitEvolution({formType: evolutionType, ...generateEvolution(values)}, media);
        }
        // if (!!media && typeof postMedia === "function") {
        //   await postMedia();
        // }
      } catch (err: any) {
        console.error(err.message);
      } finally {
        if (typeof handleClose === "function") {
          handleClose();
        }
      }
    }
  };

  const handleFailureSnackbarClose = () => {
    setFailureAlert(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={false}>
        <DialogContent>
          <WizardJsonForm
            debug={false}
            form={formData}
            onSubmit={handleSubmit}
            // TODO: tech-debt, improve error handling
            Error={({ error }: any) => error?.message}
            CloseButton={(_: any) => (
              <Button
                variant="text"
                color="secondary"
                onClick={handleCloseButton}
              >
                Cerrar
              </Button>
            )}
            SubmitButton={(props: any) => (
              <Button variant="text" color="primary" onClick={props.onSubmit}>
                Evolucionar
              </Button>
            )}
            >
            {typeof setMedia === "function" && (
              <FileLoader
                file={media}
                onFileLoaded={setMedia}
                onRemoveFile={() => setMedia("")}
              />
            )}
          </WizardJsonForm>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={failureAlert}
        autoHideDuration={4000}
        onClose={handleFailureSnackbarClose}
      >
        <Alert onClose={handleFailureSnackbarClose} severity="error">
          Debe completar el formulario primero antes de evolucionar.
        </Alert>
      </Snackbar>
    </>
  );
};

export default GFormDialog;
