import axios, { AxiosResponse } from "axios";
import { getTenantByIdEndPoint, getUserDataEndPoint, postLoginEndPoint, postLogoutEndPoint, postPasswordRestoreEndPoint } from "../../shared/utils/apis";
import { HTTP_RESPONSE } from "../../shared/utils/model/http";
import { TENANT } from "../../shared/utils/model/tenant";
import { LOG_OUT, SET_SESSION, SET_TENANT, SET_USER_DATA } from "../action_types";
import { getTenantFailure, getUserDataFailure, postLoginFailure, postLogoutFailure, postRestorePasswordFailure } from "./failureActions";
import { getTenantRequest, getUserDataRequest, postLoginRequest, postRestorePasswordRequest } from "./requestActions";
import { getTenantSuccess, getUserDataSuccess, postLoginSuccess, postRestorePasswordSuccess } from "./successActions";

export interface POST_LOGIN_BODY {
    username: string,
    password: string,
    applicationId: string,
    tenantCode: string
}

export interface POST_LOGIN_RESPONSE {
    accessToken: string;
    tokenType: string;
    expiresIn: number;
    refreshToken: string;
}

export interface USER_DATA_RESPONSE{
    id: number;
    name: string;
    surname: string;
    fullName: string;
    username: string;
    lastUsername: string;
    email: string;
    phoneNumber: string;
    mobilePhoneNumber: string;
    fullProvider: boolean;
    hasSignature: boolean;
    dni: string;
    medicalRegistration: string;
    photoDataUrl: string;
    medicalSpecialties: { name: string }[];
    cuit: string;
 }

 export interface POST_PASS_RESTORE_BODY {
    email: string,
    applicationId: string,
    tenantId: number,
}

 export interface POST_PASS_RESTORE_RESPONSE {
    active: boolean,
    applicationId: number,
    createdTimeStamp: string,
    email: string,
    entityId: number,
    fusionAuthUuid: string,
    id: number,
    lastPasswordSetTimeStamp: number,
    lastRequestedTimeStamp: string,
    tenant: TENANT,
}

export const _setUserData = (payload: any) => ({
    type: SET_USER_DATA,
    payload,
});
export const _setSession = (payload: any) => ({
    type: SET_SESSION,
    payload,
});
export const logOut = () => ({
    type: LOG_OUT,
});

export const setSession = (body: POST_LOGIN_BODY) => async (dispatch: any) => {
    const { username, password, applicationId, tenantCode } = body;
    dispatch(postLoginRequest());
    try {
        const loginResponse: AxiosResponse<HTTP_RESPONSE<POST_LOGIN_RESPONSE>> = await axios.post(
            postLoginEndPoint(),
            {
                username,
                password,
                applicationId,
                tenantCode: parseInt(tenantCode)
            },
            { timeout: 6000 },
        );
        const session = {
            accessToken: loginResponse.data.data.accessToken,
            refreshToken: loginResponse.data.data.refreshToken
        }
        dispatch({
            type: SET_SESSION,
            payload: {...session} 
        });
        dispatch(postLoginSuccess());
        return true
    } 
    catch (e) {
        console.error('Error login user', e.message);
        dispatch(postLoginFailure());
    }
}

export const setUserData = () => async (dispatch: any) => {
    dispatch(getUserDataRequest());
    try{        
        const getUserDataResponse: AxiosResponse<HTTP_RESPONSE<USER_DATA_RESPONSE>> = await axios.get(
            getUserDataEndPoint(), { timeout: 5000 }
        );

        dispatch({
            type: SET_USER_DATA,
            payload: {...getUserDataResponse.data.data}
        }) 
        dispatch(getUserDataSuccess());
        return('data ready');
    }
    catch(error){
        console.error(error.message);
        dispatch(getUserDataFailure());
    }
}

export const setTenant = (tenantCode :string) => async (dispatch: any) => {
    dispatch(getTenantRequest());
    try{
        const tenantResponse = await axios.get(
            getTenantByIdEndPoint(tenantCode), {timeout: 5000}
        )
        dispatch({
            type: SET_TENANT,
            payload: {...tenantResponse.data.data},
        })
        dispatch(getTenantSuccess());
        return tenantResponse.data;
    }
    catch(error){
        console.error('Could not get tenant id. ',error.message);
        dispatch(getTenantFailure());
    }
} 

export const postLogout = () => async (dispatch: any) => {
    dispatch(postLoginRequest());
    try{
        const logoutResponse = await axios.post(
            postLogoutEndPoint(),{timeout: 5000}
        )
        localStorage.clear();
        dispatch(logOut());
        dispatch(postLoginSuccess());
        return logoutResponse;
    }
    catch(error){
        console.error("Could not logout. ",error.message);
        dispatch(logOut());
        dispatch(postLogoutFailure());
    }
}

export const postRestorePassword = (body: POST_PASS_RESTORE_BODY) => async (dispatch: any) => {
    const { email, applicationId, tenantId } = body;
    dispatch(postRestorePasswordRequest());
    try{
        const restoreResponse: AxiosResponse<HTTP_RESPONSE<POST_PASS_RESTORE_RESPONSE>> = await axios.post(
            postPasswordRestoreEndPoint(),
            {
                email,
                applicationId,
                tenantId
            },
            { timeout: 6000 },
        );
        dispatch(postRestorePasswordSuccess());
    }
    catch(error){
        console.error('Could not restore password. ',error.message);
        dispatch(postRestorePasswordFailure());
    }
}