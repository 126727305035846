import React from "react";
import { format } from "date-fns";
import esLocale from "date-fns/locale/es";
import { useTheme } from "@material-ui/core";
import { connectField, FieldProps, filterDOMProps } from "uniforms";
import { DateTimePicker, DateTimePickerProps } from "@material-ui/pickers";
import { parse } from "date-fns/esm";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";

export type DateTimeFieldProps = FieldProps<string, DateTimePickerProps>;

function DateTimeField({
  name,
  label,
  value,
  placeholder,
  disabled,
  error,
  errorMessage,
  showInlineError,
  readOnly,
  helperText,
  InputLabelProps,
  onChange,
  ...rest
}: DateTimeFieldProps) {
  let theme = useTheme();
  let themeProps = theme.props?.MuiTextField;

  let uniformsProps = rest?.field?.uniforms ?? {};
  let dateFormat = uniformsProps?.format ?? "dd/MM/yyyy HH:mm";

  let inputValue: ParsableDate = !!value ? value : null;

  if (typeof inputValue === "string") {
    inputValue = parse(inputValue, dateFormat, new Date(), {
      locale: esLocale,
    });
  }

  return (
    <DateTimePicker
      ampm={false}
      disabled={disabled}
      error={!!error}
      format={dateFormat}
      fullWidth={themeProps?.fullWidth ?? true}
      helperText={(error && showInlineError && errorMessage) || helperText}
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps,
      }}
      inputProps={{
        readOnly,
        ...(themeProps?.inputProps ?? {}),
      }}
      margin={themeProps?.margin ?? "dense"}
      name={name}
      label={label}
      value={inputValue}
      placeholder={placeholder}
      onChange={(value: Date) => {
        let dateString = format(value, dateFormat, {
          locale: esLocale,
        });

        onChange(dateString);
      }}
      {...filterDOMProps(rest)}
      {...uniformsProps}
    />
  );
}

export default connectField<DateTimeFieldProps>(DateTimeField, {
  initialValue: false,
  kind: "leaf",
});
