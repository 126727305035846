import moment from "moment";
import { formatDate } from "../../shared/utils/dates";
import { ACTION } from "../../shared/utils/model/action";
import { EVOLUTION } from "../../shared/utils/model/evolution";
import { UPDATE_EVOLUTIONS, SET_EVOLUTION_MEDIA } from "../action_types";
const _ = require("lodash");

interface evolutionsState {
  byId: { [id: string]: EVOLUTION };
  byDate: { [id: string]: string[] };
  allIds: string[];
  allDates: string[];
}

const initialState: evolutionsState = {
  byId: {},
  byDate: {},
  allIds: [],
  allDates: [],
};

function evolution(
  state: evolutionsState = initialState,
  action: any
): Partial<evolutionsState> {
  switch (action.type) {
    case UPDATE_EVOLUTIONS: {
      const _state: evolutionsState = {
        byId: {},
        byDate: {},
        allIds: [],
        allDates: [],
      };
      const evolutions = action.payload;

      if (evolutions.length > 0) {
        _state.byId = _.mapKeys(evolutions, "id");
        _state.allIds = Object.keys(_state.byId);
        let dates: string[] = [];

        _state.allIds = _.orderBy(
          _state.allIds,
          [
            (evolutionId: string) => {
              const { date } = _state.byId[evolutionId];
              return moment(date).format("YYYYMMDD");
            },
          ],
          ["desc"]
        );

        _state.allIds.forEach((evolutionId) => {
          const { activityDate } = _state.byId[evolutionId];
          const formattedDate = formatDate(activityDate);

          if (_state.byDate.hasOwnProperty(formattedDate)) {
            _state.byDate[formattedDate].push(evolutionId);
          } else {
            _state.byDate[formattedDate] = [evolutionId];
            dates.push(activityDate);
          }
        });

        dates = _.orderBy(
          dates,
          [
            (activityDate: string) => {
              return moment(activityDate).format("YYYYMMDD");
            },
          ],
          ["desc"]
        );

        _state.allDates = dates.map((activityDate) => formatDate(activityDate));
      } else {
        _state.byDate = initialState.byDate;
        _state.allDates = initialState.allDates;
        _state.byId = initialState.byId;
        _state.allIds = initialState.allIds;
      }
      return _state;
    }
    case SET_EVOLUTION_MEDIA: {
      const { evolutionId, mediaUrls } = action.payload;
      const photoDataUrl = mediaUrls[0];
      state.byId[evolutionId].photoDataUrl = photoDataUrl;

      return state;
    }
    default:
      return state;
  }
}

export default evolution;
