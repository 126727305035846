import { ACTION } from "../../shared/utils/model/action";
import { CASES } from "../../shared/utils/model/cases";
import { SET_CASES, UPDATE_CASES } from "../action_types";
const _ = require("lodash"); 

const initialState: Partial <CASES> = {};

function cases(state: Partial<CASES> = initialState , action: ACTION<CASES>): Partial<CASES> {
    switch (action.type) {
        case UPDATE_CASES:{
            const cases = _.mapKeys(action.payload, 'id');
            return { ...cases }
        }
        default:
            return state
    }
}

export default cases;