import React, { useEffect } from "react";
import "./CaseDetails.css";
import { connect } from "react-redux";
import { setActualCase } from "../../redux/actions/caseActions";
import DetailsCard from "./components/DetailsCard/DetailsCard";
import ProvisionsList from "./components/ProvisionsList/ProvisionsList";
import AtentionsList from "./components/AtentionsList/AtentionsList";
import EvolutionsList from "./components/EvolutionsList/EvolutionsList";
import { updateAttentions } from "../../redux/actions/attentionActions";
import { updateEvolutions } from "../../redux/actions/evolutionActions";
import { CASES } from "../../shared/utils/model/cases";
import { getCaseById } from "../../redux/actions/caseActions";
import { setHeader } from "../../redux/actions/headerActions";
import WaitingAnimation from "../../shared/modules/WaitingAnimation/WaitingAnimation";
import { requestStateSelector } from "../../redux/reducers/requestStateReducer";
import { updateProvisions } from "../../redux/actions/provisionActions";

interface props {
  cases: { [key: number]: CASES };
  location: any;
  requestState: string;
  setActualCase: any;
  getCaseById: any;
  updateAttentions: any;
  updateEvolutions: any;
  updateProvisions: any;
  setHeader: any;
}

const CaseDetails = ({
  cases,
  location,
  requestState,
  setActualCase,
  getCaseById,
  updateAttentions,
  updateEvolutions,
  updateProvisions,
  setHeader,
}: props) => {
  const caseId: number = parseInt(location.pathname.split("/")[2]);

  useEffect(() => {
    setHeader(`Caso: ${caseId}`);
    setActualCase(caseId);
    if (!!!cases[caseId]) {
      getCaseById(caseId);
    }
    updateAttentions(caseId);
    updateEvolutions(caseId);
    updateProvisions(caseId);
  }, []);

  return (
    <>
      {requestState != "REQUEST" ? (
        <div>
          <div style={{ margin: "20px" }}>
            <DetailsCard />
          </div>
          <div style={{ margin: "20px" }}>
            <ProvisionsList />
          </div>
          <div style={{ margin: "20px" }}>
            <AtentionsList />
          </div>
          <div style={{ margin: "20px" }}>
            <EvolutionsList />
          </div>
        </div>
      ) : (
        <WaitingAnimation width={75} height={75} />
      )}
    </>
  );
};

const mapDispatchToProps = {
  setActualCase,
  getCaseById,
  updateAttentions,
  updateEvolutions,
  updateProvisions,
  setHeader,
};

const mapStatesToProps = (state: any, props: any) => {
  const cases: { [key: number]: CASES } = state.cases;
  const requestState = requestStateSelector("GET_CASE_BY_ID_", state);
  return {
    cases,
    requestState,
  };
};

export default connect(mapStatesToProps, mapDispatchToProps)(CaseDetails);
