import { 
    GET_CASES_CLOSE, 
    GET_CASE_BY_ID_CLOSE, 
    UPDATE_ATTENTIONS_CLOSE, 
    POST_ATTENTION_CLOSE,
    GET_PROVISIONS_CLOSE,
    GET_TENANT_CLOSE,
    POST_LOGIN_CLOSE,
    GET_USER_DATA_CLOSE,
    POST_LOGOUT_CLOSE,
    POST_RESTORE_PASSWORD_CLOSE,
    PATCH_ATTENTION_CLOSE,
    POST_EVOLUTION_FORM_CLOSE,
    POST_GFORM_EVOLUTION_CLOSE,
    POST_EVOLUTION_CLOSE,
    GET_ATTENTIONS_BY_QPS_CLOSE,
    GET_CATEGORIES_CLOSE
} from "../action_types";

export const getCasesClose= () => ({
    type: GET_CASES_CLOSE,
});

export const getCaseByIdClose = () => ({
    type: GET_CASE_BY_ID_CLOSE,
});

export const updateAttentionClose = () => ({
    type: UPDATE_ATTENTIONS_CLOSE,
});

export const postAttentionClose = () => ({
    type: POST_ATTENTION_CLOSE,
});

export const getProvisionsClose = () => ({
    type: GET_PROVISIONS_CLOSE,
});

export const getTenantClose = () => ({
    type: GET_TENANT_CLOSE,
});

export const postLoginClose = () => ({
    type: POST_LOGIN_CLOSE,
});

export const getUserDataClose = () => ({
    type: GET_USER_DATA_CLOSE,
});

export const postLogoutClose = () => ({
    type: POST_LOGOUT_CLOSE,
});

export const postRestorePasswordClose = () => ({
    type: POST_RESTORE_PASSWORD_CLOSE,
});

export const patchAttentionClose = () => ({
    type: PATCH_ATTENTION_CLOSE,
});

export const postEvolutionFormClose = () => ({
    type: POST_EVOLUTION_FORM_CLOSE,
});

export const postGFormEvolutionClose = () => ({
    type: POST_GFORM_EVOLUTION_CLOSE,
});

export const postEvolutionClose = () => ({
    type: POST_EVOLUTION_CLOSE,
});

export const getAttentionsByQPSClose = () => ({
    type: GET_ATTENTIONS_BY_QPS_CLOSE,
});

export const getCategoriesClose = () => ({
    type: GET_CATEGORIES_CLOSE,
});

