import React from 'react';

import Lottie from 'react-lottie';
import animationData from '../../../assets/Lottie/loading-dots.json';

export default function WaitingAnimation(props: any) {

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <Lottie options={defaultOptions}
            height={props.height}
            width={props.width} 
            // isStopped={false}
            // isPaused={false}
        />
    );
}