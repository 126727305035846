import React, { useEffect, useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Chip, IconButton, TableCell } from '@material-ui/core';

import styles from './AttentionRow.module.css';
import EvolveAttentionDialog from '../AttentionDialogs/EvolveAttentionDialog';
import { postEvolutionForm } from '../../../../redux/actions/evolutionActions';
import GFormDialog from '../AttentionDialogs/GFormDialog';
import moment from 'moment';
import { ATTENTION } from '../../../../shared/utils/model/attention';
import { connect } from 'react-redux';

const color = [
    'white', // no hay id 0 en el estado
    '#a7c6b9',
    '#5FE8D4',
    '#61F27E',
    '#E89A5F',
    '#B85FE8',
    '#F0E662',
    '#D7B1F2',
    '#7C5DE3',
    '#E6684C'
]

interface props{
    attention: ATTENTION,
    postEvolutionForm: any,
}

const AttentionRow = ({attention, postEvolutionForm}:props) => {
    const [open, setOpen] = useState(false);
    const [formResponse, setFormResponse] = useState({});
    const [isResponse, setIsResponse] = useState(false);

    const _fromDate= moment(attention.fromDate).toDate();
    const dateIn = new Date(_fromDate)

    const handleClickOpen = async () => {
        if (!isResponse)
            await getEvolutionForm();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getEvolutionForm = async () => {
        try {
            const response = await postEvolutionForm(attention.id);
            if (response.data !== null) {
                setFormResponse({ ...response.data });
                setIsResponse(true);
            }
        }
        catch (error) {
            console.error('Error al solicitar formulario: ', error);
        }
    }

    const formatDate = (date: Date) => {
        if (!!date) {
            let _date: any = date.getDate();
            if (_date < 10) { _date = "0" + _date; }
            let _monthFrom: any = dateIn.getMonth() + 1;
            if (_monthFrom < 10) { _monthFrom = "0" + _monthFrom; }
            let _dateIn = moment(dateIn);
            let _hoursFrom: any = _dateIn.hour();
            if (_hoursFrom < 10) { _hoursFrom = "0" + _hoursFrom; }
            let _minutesFrom: any = _dateIn.minutes();
            if (_minutesFrom < 10) { _minutesFrom = "0" + _minutesFrom; }
            return (_date + '/' + _monthFrom + '/' + dateIn.getFullYear() + ' ' + _hoursFrom + ':' + _minutesFrom);
        } else {
            return ''
        }
    }
    return (
        <>
            <TableRow className={styles.tableRow}>
                <TableCell>
                    {formatDate(dateIn)}
                    {!!attention.toDate && ('-' + formatDate(new Date(moment(attention.toDate).toDate())))}

                </TableCell>
                <TableCell>
                    {attention.practice.name}
                </TableCell>
                <TableCell>
                    <Chip
                        size="small"
                        label={attention.state.name}
                        style={{
                            backgroundColor: color[attention.state.id],
                            color: 'white',
                            width: '80px'
                        }}
                    />
                </TableCell>
                <TableCell>
                    {(attention.state.name === 'Agendada' || attention.state.name === 'En Curso' || attention.state.name === 'Realizada') &&
                        <IconButton
                            style={{ padding: '0px' }}
                            onClick={handleClickOpen}
                        >
                            <AddCircleIcon
                                color='primary'
                            />
                        </IconButton>
                    }
                </TableCell>
            </TableRow>
            <EvolveAttentionDialog
                attention={attention}
                open={open}
                showDescription={!isResponse}
                formData={formResponse}
                handleClose={handleClose}
            />
        </>
    )
}

const mapDispatchToProps = {
    postEvolutionForm,
}

export default connect(null, mapDispatchToProps)(AttentionRow);