import React from "react";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from "@date-io/date-fns";
import { AutoField, AutoForm } from "uniforms-material";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Box, Button, Grid, Typography } from "@material-ui/core";

import useWizardJsonForm from "./hook";

type ErrorProps = {
  error: Error;
};

export type WizardJsonFormProps = {
  form?: any;
  children?: any;
  debug?: boolean;
  initialStep?: number;
  CloseButton: (props: any) => React.ReactElement | null;
  SubmitButton: (props: any) => React.ReactElement | null;
  Error?: (props?: ErrorProps) => React.ReactElement | null;
  onSubmit?: (type: any, allValues: any) => Promise<void> | void;
};

let NoopRender = () => null;

export default function WizardJsonForm({
  form,
  children,
  onSubmit,
  debug = false,
  initialStep = 0,
  Error = NoopRender,
  CloseButton = NoopRender,
  SubmitButton = NoopRender,
}: WizardJsonFormProps) {
  let {
    step,
    error,
    schema,
    values,
    formRef,
    jsonSchema,
    shouldRenderBackButton,
    shouldRenderNextButton,
    shouldRenderSubmitButton,
    handleSubmit,
    handleGoBack,
    handleGoNext,
    handleChangeModel,
    customComponentDetector,
  } = useWizardJsonForm({
    form,
    initialStep,
    onSubmit,
  });

  if (!!error) {
    return <Error error={error} />;
  }

  if (!!schema) {
    return (
      <Grid spacing={2} container>
        {!!schema.title && (
          <Grid md={12} item>
            <Typography variant="h6" gutterBottom>
              {schema.title}
            </Typography>
          </Grid>
        )}
        <Grid md={12} item>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <AutoField.componentDetectorContext.Provider
              value={customComponentDetector}
            >
              <AutoForm
                ref={formRef}
                validate="onSubmit"
                schema={jsonSchema}
                submitField={() => null}
                onChangeModel={handleChangeModel}
              />
            </AutoField.componentDetectorContext.Provider>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid md={12} item>
          {children}
        </Grid>
        <Grid md={12} item>
          <Grid spacing={0} container>
            <Grid md={6} item>
              <CloseButton />
            </Grid>
            <Grid md={6} item>
              <Box style={{ float: "right" }}>
                {shouldRenderBackButton && (
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={handleGoBack}
                  >
                    Anterior
                  </Button>
                )}
                {shouldRenderNextButton && (
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={handleGoNext}
                  >
                    Siguiente
                  </Button>
                )}
                {shouldRenderSubmitButton && (
                  <SubmitButton onSubmit={handleSubmit} />
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {debug && (
          <>
            <Grid md={12} item>
              Current Step: {step}
            </Grid>
            <Grid md={12} item>
              {JSON.stringify(values, null, 2)}
            </Grid>
          </>
        )}
      </Grid>
    );
  }

  return null;
}
