import React, { useState } from "react";
import {
  Chip,
  IconButton,
  TableRow,
  TableCell,
  Collapse,
  Box,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import EvolutionRow from "../EvolutionRow/EvolutionRow";
import styles from "./EvolutionsGroup.module.css";
import { EVOLUTION } from "../../../../shared/utils/model/evolution";
import { connect } from "react-redux";

interface props {
  date: string;
  evolutionIds: string[];
  evolutionsById: { [key: string]: EVOLUTION };
}

const EvolutionsGroup = ({ date, evolutionIds, evolutionsById }: props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow className={styles.tableRow} onClick={() => setOpen(!open)}>
        <TableCell className={styles.tableCell}>{date}</TableCell>
        <TableCell className={styles.tableCell} align="center">
          <Chip size="small" label={evolutionIds.length} />
        </TableCell>
        <TableCell className={styles.tableCell} align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={0}>
              {evolutionIds.map((id) => (
                <EvolutionRow key={id} evolutionId={id} />
              ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const mapDispatchToProps = null;

const mapStatesToProps = (state: any, props: any) => {
  const evolutionsById: { [key: string]: EVOLUTION } = state.evolutions.byId;

  return {
    evolutionsById,
  };
};

export default connect(mapStatesToProps, mapDispatchToProps)(EvolutionsGroup);
