import React from 'react';
import Button from '@material-ui/core/Button';

import './login-button.css';
import WaitingAnimation from '../../../../shared/modules/WaitingAnimation/WaitingAnimation';
import { requestStateSelector } from '../../../../redux/reducers/requestStateReducer';
import { connect } from 'react-redux';

interface props{
    label: string,
    disabled: boolean,
    type: any,
    onClick: any,
    requestStateSession: string,
}

const LoginButton = ({ label, disabled, type, onClick, requestStateSession }:props ) => {
    return (
        <div style={{margin: 10}}>
            <Button
                style={{width: '100%', color: 'white'}}
                type={type}
                color='primary'
                variant="contained"
                disabled={disabled}
                onClick={onClick}
            >
                {(requestStateSession!='REQUEST') ? label : <WaitingAnimation width={20} height={20}/>}
            </Button>
        </div>
    )
}

const mapStatesToProps = (state: any, props: any) => {
  const requestStateSession= requestStateSelector('POST_LOGIN_', state);
    return { 
        requestStateSession,
    }
  }

export default connect(mapStatesToProps, null)(LoginButton);