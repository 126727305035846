export const parseUri = (uri: string) => {
  const uriArray = uri.split(";");
  const mediaType = uriArray[0].split(":")[1];
  const base64 = uriArray[1].split(",")[1];
  const type = mediaType.split("/")[0];
  const extension = mediaType.split("/")[1];

  return {
    type,
    extension,
    base64,
  };
};
