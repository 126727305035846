import { ACTION } from "../../shared/utils/model/action";
import { PROVISION }  from "../../shared/utils/model/provision";
import { SET_PROVISIONS, UPDATE_PROVISIONS } from "../action_types";
const _ = require("lodash"); 

const initialState: Partial <PROVISION> = {};

function provision(state: Partial<PROVISION> = initialState , action: ACTION<PROVISION>): Partial<PROVISION> {
    switch (action.type) {
        case SET_PROVISIONS:
            return { ...state, ...action.payload }
        case UPDATE_PROVISIONS:{
            const provisions = _.mapKeys(action.payload, 'id');
            return { ...provisions }
        }
        default:
            return state
    }
}

export default provision;