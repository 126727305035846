import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";

interface props {
  open: boolean;
  onDismiss: () => void;
  onSelectOption: (value: boolean) => void;
}

const EvolutionDateDialog = ({ open, onDismiss, onSelectOption }: props) => (
  <Dialog open={open} onClose={onDismiss} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">
      <Typography variant="h5" color="secondary">
        Seleccione un horario para la atención
      </Typography>
    </DialogTitle>
    <DialogContent>
      <Button
        onClick={() => onSelectOption(true)}
        variant="contained"
        color="primary"
        style={{ color: "white", width: "100%", marginBottom: 16 }}
      >
        Fecha y hora agendada
      </Button>
      {/* <Button
        onClick={() => onSelectOption(false)}
        variant="contained"
        color="primary"
        style={{ color: "white", width: "100%", marginBottom: 16 }}
      >
        Fecha y hora manual
      </Button> */}
    </DialogContent>
  </Dialog>
);

export default EvolutionDateDialog;
