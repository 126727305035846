import { ACTION } from "../../shared/utils/model/action";
import { USER } from "../../shared/utils/model/user";
import { SET_SESSION, SET_TENANT, SET_USER_DATA } from "../action_types";

const initialState = {};

function user(state: Partial<USER> = initialState, action: ACTION<USER>): USER {
    
    switch (action.type) {
        case SET_USER_DATA:{
            const newState = { ...state, data: { ...state.data, ...action.payload } }; 
            localStorage.setItem('user', JSON.stringify(newState));
            return newState
        }
        case SET_SESSION:{
            const newState = { ...state, session: { ...(state.session), ...(action.payload) } } 
            localStorage.setItem('user', JSON.stringify(newState));
            return newState
        }
        case SET_TENANT:{
            return { ...state, tenant: { ...(state.tenant), ...(action.payload) } } 
        }
        default:{  
            return state
        }
    }
}

export default user;