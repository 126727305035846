import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import { TableRow, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import styles from "./EvolutionsList.module.css";
import { EVOLUTION } from "../../../../shared/utils/model/evolution";
import WaitingAnimation from "../../../../shared/modules/WaitingAnimation/WaitingAnimation";
import EvolutionsGroup from "../EvolutionsGroup/EvolutionsGroup";
import { requestStateSelector } from "../../../../redux/reducers/requestStateReducer";

const Headers: {
  title: string;
  align: "left" | "right" | "center";
  width: string;
}[] = [
  { title: "Fecha de la atención", align: "left", width: "70%" },
  { title: "Evoluciones", align: "center", width: "15%" },
  { title: "", align: "center", width: "15%" },
];

interface props {
  evolutionDates: string[];
  evolutionIdsByDate: { [key: string]: string[] };
  requestState: string;
}

const EvolutionsList = ({
  evolutionDates,
  requestState,
  evolutionIdsByDate,
}: props) => {
  return (
    <>
      <Card>
        <CardHeader
          style={{ textAlign: "center" }}
          title={
            <Typography variant="h6" color="secondary">
              Lista de Evoluciones
            </Typography>
          }
        />
        <CardContent>
          {requestState != "REQUEST" ? (
            <Paper elevation={3}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {Headers.map((header, i) => {
                        return (
                          <TableCell
                            style={{ width: header.width }}
                            align={header.align}
                            key={i}
                          >
                            <b>{header.title}</b>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  {evolutionDates.length != 0 ? (
                    <TableBody>
                      {evolutionDates.map((date: string) => {
                        return (
                          <EvolutionsGroup
                            key={date}
                            date={date}
                            evolutionIds={evolutionIdsByDate[date]}
                          />
                        );
                      })}
                    </TableBody>
                  ) : null}
                </Table>
              </TableContainer>
              {evolutionDates.length == 0 ? (
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  className={styles.noAttCont}
                >
                  <Grid item className={styles.noAttText}>
                    <b>No se han encontrado evoluciones</b>
                  </Grid>
                </Grid>
              ) : null}
            </Paper>
          ) : (
            <WaitingAnimation height={75} width={75} />
          )}
        </CardContent>
      </Card>
    </>
  );
};

const mapDispatchToProps = null;

const mapStatesToProps = (state: any, props: any) => {
  const evolutionDates: string[] = state.evolutions.allDates;
  const evolutionIdsByDate: { [key: string]: string[] } =
    state.evolutions.byDate;
  const requestState = requestStateSelector("UPDATE_EVOLUTIONS_", state);

  return {
    evolutionDates,
    evolutionIdsByDate,
    requestState,
  };
};

export default connect(mapStatesToProps, mapDispatchToProps)(EvolutionsList);
