import { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import FileLoader from "../FileLoader/FileLoader";
import WaitingAnimation from "../../../../shared/modules/WaitingAnimation/WaitingAnimation";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import {
  updateAttention,
  patchAttention,
} from "../../../../redux/actions/attentionActions";
import { postEvolution } from "../../../../redux/actions/evolutionActions";
import {
  patchAttentionClose,
  postEvolutionClose,
} from "../../../../redux/actions/closeActions";
import EVOLUTION_TYPE from "../../../../shared/utils/enums/evolutionsTypes";
import ATTENTION_STATE from "../../../../shared/utils/enums/attentionState";

interface props {
  attention: any;
  open: boolean;
  handleClose: any;
  postEvolution: any;
  patchAttention: any;
  postEvolutionClose: any;
  patchAttentionClose: any;
  updateAttention: any;
  onSuccess: any;
}

const CancelAttentionDialog = ({
  attention,
  open,
  handleClose,
  postEvolution,
  patchAttention,
  postEvolutionClose,
  patchAttentionClose,
  updateAttention,
  onSuccess,
}: props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(null);
  const [reason, setReason] = useState(null);
  const [validReason, setValidReason] = useState(false);
  const [validDescription, setValidDescription] = useState(false);
  const [reasonError, setReasonError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [media, setMedia] = useState<any>("");
  const [snackbar, setSnackbar] = useState<any>({
    open: false,
    severity: undefined,
    message: "",
  });

  const validateReason = (reason: string) => {
    if (!!reason) {
      setReasonError(false);
      setValidReason(true);
    } else {
      setReasonError(true);
      setValidReason(false);
    }
  };

  const validateDescription = (value: string) => {
    if (value.length < 10) {
      setDescriptionError(true);
      setValidDescription(false);
    } else {
      setDescriptionError(false);
      setValidDescription(true);
    }
  };

  const reasonHandler = (event: any) => {
    validateReason(event.target.value);
    setReason(event.target.value);
  };

  const descriptionHandler = (event: any) => {
    validateDescription(event.target.value);
    setDescription(event.target.value);
  };

  const sendButtonHandler = async () => {
    setLoading(true);
    try {
      await postEvolution(
        attention.id,
        moment(),
        description,
        reason,
        media || null
      );

      await patchAttention(
        attention.id,
        null,
        null,
        null,
        ATTENTION_STATE.NOT_DONE
      );

      const modifiedAttention = {
        ...attention,
        state: { id: ATTENTION_STATE.NOT_DONE, name: "No realizada" },
      };

      setSnackbar({
        open: true,
        severity: "success",
        message: "Atención cancelada con éxito",
      });
      updateAttention(modifiedAttention);
      onSuccess(modifiedAttention);
    } catch (error) {
      setSnackbar({
        open: true,
        severity: "error",
        message: "No se ha podido cancelar la atención",
      });
    } finally {
      handleCloseDialog();
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({
      open: false,
      severity: undefined,
      message: "",
    });
    dispatch(patchAttentionClose());
    dispatch(postEvolutionClose());
  };

  const handleCloseDialog = () => {
    setReason(null);
    setDescription(null);
    setValidReason(false);
    setValidDescription(false);
    setReasonError(false);
    setDescriptionError(false);
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h5" color="secondary">
            Cancelar atención
          </Typography>
        </DialogTitle>
        <DialogContent style={{ maxWidth: 537 }}>
          <DialogContentText>
            ¿Por qué no se realizará la atención?
          </DialogContentText>
          <Grid container spacing={1}>
            <Grid item md={12}>
              <TextField
                error={descriptionError}
                onChange={descriptionHandler}
                margin="dense"
                id="description"
                label="Ingrese descripcion"
                type="text"
                multiline
                fullWidth
              />
            </Grid>
            <Grid item md={12}>
              <FormControl fullWidth>
                <InputLabel id="select-label">
                  Seleccione por que no realizará la atención
                </InputLabel>
                <Select
                  error={reasonError}
                  labelId="select-label"
                  id="simple-select"
                  value={reason}
                  onChange={reasonHandler}
                >
                  <MenuItem value={EVOLUTION_TYPE.ABSENT_PATIENT}>
                    Paciente ausente
                  </MenuItem>
                  <MenuItem value={EVOLUTION_TYPE.NOT_GOING}>
                    Inasistencia del prestador
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12} style={{ marginTop: 24, marginBottom: 8 }}>
              <FileLoader
                file={media}
                onFileLoaded={setMedia}
                onRemoveFile={() => setMedia("")}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="secondary"
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            onClick={sendButtonHandler}
            variant="contained"
            color="primary"
            disabled={loading || !validReason || !validDescription}
            style={{ color: "white" }}
          >
            {loading ? <WaitingAnimation height={24} width={43} /> : "Enviar"}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = {
  updateAttention,
  postEvolution,
  patchAttention,
  patchAttentionClose,
  postEvolutionClose,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelAttentionDialog);
