import axios from "axios";
import { connect } from "react-redux";
import { setSession, _setSession } from "../../redux/actions/userActions";
import store from '../../redux/store';

export const requestInterceptor = axios.interceptors.request.use(
    function (req) {
        const state = store.getState();
        const user = state?.user;
        
        if (user?.session?.accessToken && user?.session?.refreshToken) {
            
            req.headers.Authorization = `Bearer ${user.session.accessToken}`;
            req.headers.RefreshToken = user.session.refreshToken;
        }
        return req;
    },
    function (error) {
        console.error(error);
        return Promise.reject(error);
    }
);

export const responseInterceptor = axios.interceptors.response.use(
    function (response) {
        const accessToken = response.headers['x-amzn-remapped-authorization'];
        if (accessToken) {
            store.dispatch(
                _setSession({ accessToken })
            );
        }
        return response;
    },
    function (error) {
        console.error(error);
        return Promise.reject(error);
    }
);