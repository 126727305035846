import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

import Media from "../Media/Media";
import styles from "./EvolutionRow.module.css";
import moment from "moment";
import WaitingAnimation from "../../../../shared/modules/WaitingAnimation/WaitingAnimation";
import { getEvolutionPhoto } from "../../../../redux/actions/evolutionActions";
import { EVOLUTION } from "../../../../shared/utils/model/evolution";
import { connect } from "react-redux";

interface props {
  evolutionId: number;
  getEvolutionPhoto: any;
  evolutionsById: { [key: string]: EVOLUTION };
}

const EvolutionRow = ({
  evolutionId,
  evolutionsById,
  getEvolutionPhoto,
}: props) => {
  const [mediaLoaded, setMediaLoaded] = useState(false);

  useEffect(() => {
    if (
      evolutionsById[evolutionId] &&
      evolutionsById[evolutionId]?.hasMedia &&
      !evolutionsById[evolutionId]?.photoDataUrl
    ) {
      getEvolutionPhoto(evolutionId).then(() => {
        setMediaLoaded(true);
      });
    } else if (evolutionsById[evolutionId].photoDataUrl) {
      setMediaLoaded(true);
    }
  }, []);

  return (
    <Grid container className={styles.evolutionContainer}>
      <Grid item sm={6} md={6} style={{ color: "#616161", fontWeight: "bold" }}>
        {evolutionsById[evolutionId].provider.name}
      </Grid>
      <Grid item sm={6} md={6} style={{ color: "#616161", textAlign: "right" }}>
        {`${moment(evolutionsById[evolutionId].date).format(
          "DD/MM/YYYY HH:mm"
        )} hs`}
      </Grid>
      <Grid item sm={12} md={12} style={{ color: "#616161", marginTop: 8 }}>
        <i>{evolutionsById[evolutionId].text}</i>
      </Grid>
      <Grid item sm={12} md={12} style={{ color: "#616161" }}>
        {evolutionsById[evolutionId]?.hasMedia &&
        !evolutionsById[evolutionId]?.photoDataUrl ? (
          <div className={styles.loadingContainer}>
            <WaitingAnimation height={35} width={35} />
          </div>
        ) : mediaLoaded && evolutionsById[evolutionId]?.photoDataUrl ? (
          <Media
            id={evolutionId}
            mediaData={evolutionsById[evolutionId].photoDataUrl}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = {
  getEvolutionPhoto,
};

const mapStatesToProps = (state: any, props: any) => {
  const evolutionsById: { [key: string]: EVOLUTION } = state.evolutions.byId;

  return {
    evolutionsById,
  };
};

export default connect(mapStatesToProps, mapDispatchToProps)(EvolutionRow);
