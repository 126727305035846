import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import { Typography } from '@material-ui/core';

import styles from './DetailsCard.module.css';
import { CASES } from '../../../../shared/utils/model/cases';
import { setActualCase } from '../../../../redux/actions/caseActions';

interface props {
    cases: {[key: number]: CASES}
    actualCase: number,
}

const DetailsCard = ({cases, actualCase}: props) => {

    const _actualCase = cases[actualCase];
    
    return (
        <Card>
            <CardHeader
                title={<Typography variant='h5' color='secondary'>
                    {_actualCase?.patient?.name} {_actualCase?.patient?.surname}
                </Typography>}
                subheader={`DNI: ${_actualCase?.patient?.documentNumber}    CIF: ${_actualCase?.patient?.id}`}
            />
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item md={4} sm={12}>
                        <Grid container>
                            <Grid item md={1}>
                                <LocationOnIcon color='primary' />
                            </Grid>
                            <Grid item md={11} className={styles.text}>
                                {_actualCase?.patient?.address.street} {_actualCase?.patient?.address.streetNumber}, {_actualCase?.patient?.address.location}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4} sm={12}>
                        <Grid container>
                            <Grid item md={1}>
                                <PhoneIphoneIcon color='primary' />
                            </Grid>
                            <Grid item md={10} className={styles.text}>
                                {_actualCase?.patient?.phoneNumber}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4} sm={12}>
                        <Grid container>
                            <Grid item md={1}>
                                <HeadsetMicIcon color='primary' />
                            </Grid>
                            <Grid item md={10} className={styles.text}>
                                {_actualCase?.operator.name} - {_actualCase?.operator.surname}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

const mapDispatchToProps = {
    setActualCase
}

const mapStatesToProps = (state: any, props: any) => {
    const actualCase: number = state.actualCase;
    const cases: {[key: number]: CASES} = state.cases
    return {
        actualCase,
        cases,
    };
}

export default connect(mapStatesToProps, mapDispatchToProps)(DetailsCard);