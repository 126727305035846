import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { postEvolution } from '../../../../redux/actions/evolutionActions';
import { Checkbox, FormControlLabel, Snackbar, Switch } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import GFormDialog from './GFormDialog';
import { postEvolutionForm } from '../../../../redux/actions/evolutionActions';
import { useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { updateAttentions, postAttention, patchAttention } from '../../../../redux/actions/attentionActions';
import { RESTRICTION } from '../../../../shared/utils/model/restriction';
import { postEvolutionClose, postAttentionClose } from '../../../../redux/actions/closeActions';
import { requestStateSelector } from '../../../../redux/reducers/requestStateReducer';

interface props{
  agreementId: string,
  restriction: RESTRICTION,
  _open: boolean,
  handleClose: any,
  requestStateAttention: string,
  requestStateEvolution: string,
  postAttention: any,
  postEvolutionForm: any,
  postEvolution: any,
  postEvolutionClose: any,
  postAttentionClose: any,
}

const NewAttentionDialog = ({
  agreementId, 
  restriction, 
  _open, 
  handleClose, 
  requestStateAttention,
  requestStateEvolution,
  postAttention, 
  postEvolutionForm,
  postEvolution,
  postEvolutionClose,
  postAttentionClose,}: props) => {
    
  const [open, setOpen] = useState(false);
  const [fromDate, setFromDate] = useState<Date>();
  const [toDate, setToDate] = useState<Date>();
  const [quantity, setQuantity] = useState(null);
  const [description, setDescription] = useState(null);
  const [succesAlert, setSuccesAlert] = useState(false);
  const [failureAlert, setFailureAlert] = useState(false);
  const [openGForm, setOpenGForm] = useState(false);
  const [formResponse, setFormResponse] = useState({});
  const [isResponse, setIsResponse] = useState(true);
  const [openDescription, setOpenDescription] = useState(false);
  const [attentionId, setAttentionId] = useState<number>(0);
  const [criticEvolution, setCriticEvolution] = useState(false);
  const [validDate, setValidDate] = useState(false);
  const [validQuantity, setValidQuantity] = useState(false);
  const [validDescription, setValidDescription] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [quantityError, setQuantityError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const { id }: any = useParams();

  const dispatch = useDispatch();

  const validateDates = (from?: Date, to?: Date) => {
    if (to == null && from != null) {
      setValidDate(true);
    }
    else if (to != null && from != null && from < to) {
      setValidDate(true);
    }
    else {
      setValidDate(false);
      setDateError(true);
    }
  }

  const validateQuantity = (quantity: number) => {
    if (!!quantity && quantity > 0) {
      setValidQuantity(true);
    } else {
      setValidQuantity(false);
      setQuantityError(true);
    }
  }

  const validateDescription = (desc: string) => {
    if (desc.length < 4){
      setDescriptionError(true);
      setValidDescription(false);
    } else {
      setDescriptionError(false);
      setValidDescription(true);
    }
  }

  const fromDateHandler = (event: any) => {
    const newFrom = new Date(event.target.value);
    validateDates(newFrom, toDate);
    setFromDate(newFrom);
  }

  const toDateHandler = (event: any) => {
    const newTo = new Date(event.target.value);
    setToDate(new Date(event.target.value));
    validateDates(fromDate, newTo);
  }

  const quantityHandler = (event: any) => {
    const quantity: number = event.target.value;
    validateQuantity(quantity)
    setQuantity(event.target.value)
  }

  const descriptionHandler = (event: any) => {
    validateDescription(event.target.value);
    setDescription(event.target.value)
  }

  const addAttentionHandler = async () => {
    handleClose();
    setValidDate(false);
    setValidQuantity(false);
    try {
      const attentionState = 1;
      let attention;
      if (restriction.requiredToDate)
        attention = await postAttention(agreementId, fromDate, quantity, attentionState, toDate);
      else
        attention = await postAttention(agreementId, fromDate, quantity, attentionState);
      
      setAttentionId(attention.data.id);
      await getEvolutionForm(attention.data.id);
    }
    catch (error) {
      console.error('No se pudo crear la atencion.', error.message);
      setFailureAlert(true);
    }
  }

  const submitEvolution = async () => {
    try {
      await patchAttention(attentionId, fromDate, toDate, quantity);
      setSuccesAlert(true);
      await timeout(2000); //2 seconds watching success alert
      updateAttentions(id);
    }
    catch (error) {
      setFailureAlert(true);
    }
  }


  function timeout(delay: number) {
    return new Promise(res => setTimeout(res, delay));
  }

  const submitDescription = async () => {
    let evolutionType = 1;
    try {
      if (criticEvolution)
        evolutionType = 4;
      await postEvolution(attentionId, toDate, description, evolutionType);
      await patchAttention(attentionId, fromDate, toDate, quantity);
      setOpenDescription(false);
      setSuccesAlert(true);
    }
    catch (error) {
      console.info(error.message);
    }
  }

  const handleSwitchChange = () => {
    setCriticEvolution(!criticEvolution);
  }

  const getEvolutionForm = async (_attentionId: any) => {

    try {
      const response = await postEvolutionForm(_attentionId);

      if (response.data !== null) {
        setFormResponse({ ...response.data });
        setIsResponse(true);
        setOpenGForm(true);
      }
      else {
        setOpenDescription(true);
      }
    }
    catch (error) {
      console.error('Error al solicitar formulario: ', error);
    }
  }


  const handleSnackbarClose = () => {
    dispatch(postAttentionClose());
    dispatch(postEvolutionClose());
  }

  const handleCloseDescription = () => {
    setOpenDescription(false);
  }

  const handleCloseComponent = () => {
    setValidDate(false);
    setValidQuantity(false);
    handleClose();
  }

  return (
    <>
      <Dialog open={_open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <Typography variant='h5' color='secondary'>
            Nueva atencion
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Complete los cuadros que aparecen a continuacion.
            </DialogContentText>
          <Grid container spacing={1}>
            <Grid item md={12}>
              <TextField
                error={dateError}
                onChange={fromDateHandler}
                autoFocus
                margin="dense"
                id="date-start"
                label="Fecha de inicio"
                type="datetime-local"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                error={dateError}
                onChange={toDateHandler}
                margin="dense"
                id="date-end"
                label="Fecha de finalizacion"
                type="datetime-local"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                error={quantityError}
                onChange={quantityHandler}
                margin="dense"
                id="date-end"
                label="Cantidad de horas"
                type="number"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseComponent} color="secondary">
            Cancelar
          </Button>
          <Button
            onClick={addAttentionHandler} color="primary"
            disabled={!validDate || !validQuantity} variant='contained'
            style={{ color: 'white' }}
          >
            Crear
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDescription} onClose={handleCloseComponent} aria-labelledby="form-dialog-title">
        <DialogTitle>
          <Typography variant='h6' color='secondary'>
            Ingrese el estado del paciente
        </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container>
              <Grid item md={12}>
                <>
                  <TextField
                    error={descriptionError}
                    onChange={descriptionHandler}
                    margin="dense"
                    id="description"
                    label="Estado del paciente"
                    type="text"
                    multiline
                    fullWidth
                    required
                  />
                  <Grid item md={12}>
                    <FormControlLabel
                      style={{color: 'grey'}}
                      control={
                        <Checkbox
                          checked={criticEvolution}
                          onChange={handleSwitchChange}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label='Evolucion Critica'                    
                    />
                  </Grid>
                </>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDescription} color="secondary">
            Cancelar
          </Button>
          <Button onClick={submitDescription} color="primary">
            Crear
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={requestStateAttention=='SUCCESS' || requestStateEvolution=='SUCCESS'} autoHideDuration={4000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success">
          Atencion creada y realizada con exito.
        </Alert>
      </Snackbar>
      <Snackbar open={requestStateAttention=='FAILURE' || requestStateEvolution=='FAILURE'} autoHideDuration={4000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="error">
          No se pudo crear la atención.
        </Alert>
      </Snackbar>
      <GFormDialog
        formData={formResponse}
        open={openGForm}
        handleClose={() => { setOpenGForm(false) }}
        submitEvolution={submitEvolution}
      />
    </>
  );
}

const mapDispatchToProps = {
  updateAttentions,
  postAttention,
  postEvolutionForm,
  postEvolution,
  postEvolutionClose,
  postAttentionClose,
}

const mapStatesToProps = (state: any, props: any) => {
  const requestStateAttention = requestStateSelector('POST_ATTENTION_', state);
  const requestStateEvolution = requestStateSelector('POST_EVOLUTION_', state);
  return {
    requestStateAttention,
    requestStateEvolution,
  }
}

export default connect(mapStatesToProps, mapDispatchToProps)(NewAttentionDialog);