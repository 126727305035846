import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import ProfileCard from './components/ProfileCard/ProfileCard';

import { USER } from '../../shared/utils/model/user';
import { setHeader } from '../../redux/actions/headerActions';

interface props{
    user: USER,
    setHeader: any,
}

const Profile = ({user, setHeader}: props) => {
    useEffect(() => {
        setHeader('Perfil');
    }, []);

    

    return (
        <ProfileCard
            name={user?.data?.name}
            surname={user?.data?.surname}
            username={user?.data?.username}
            email={user?.data?.email}
            phoneNumber={user?.data?.phoneNumber}
        />
    );
}


const mapDispatchToProps = {
    setHeader,
}

const mapStatesToProps = (state: any, props: any) => {
    const user: USER = state.user;
    return {user}
}

export default connect(mapStatesToProps, mapDispatchToProps)(Profile);