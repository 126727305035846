import axios from "axios";
import { getCaseByIdEndPoint, getCasesEndPoint } from "../../shared/utils/apis";
import { CASES } from "../../shared/utils/model/cases";
import { SET_ACTUAL_CASE, UPDATE_CASES } from "../action_types";
import moment from "moment";

import { 
    getCasesRequest, 
    getCaseByIdRequest,
} from "./requestActions";
import { 
    getCasesSuccess, 
    getCaseByIdSuccess,
} from "./successActions";
import { 
    getCasesFailure,
    getCaseByIdFailure,
} from "./failureActions";


// Retrieve all the paginated cases at once
export const updateCases = (casesTimeOffset: any) => async (dispatch: any) => {
    const casesTimeOffset = moment().add(-32, 'days');
    dispatch(getCasesRequest());
    let data: CASES[] = [];
    try{
        const response = await axios.get(
            getCasesEndPoint({
            size: 1000,
            page: 0,
            closeDate: casesTimeOffset,
            }),
            { timeout: 60000 },
        );
        const data = response?.data?.data;
        dispatch({
            type: UPDATE_CASES,
            payload: data,
        })
        dispatch(getCasesSuccess());
    }
    catch(error){
        console.error('Could not get cases. ',error.message);
        dispatch(getCasesFailure());
    }
};

//Retrieve only a case by its id
export const getCaseById = (caseId: number) => async (dispatch: any) => {
    dispatch(getCaseByIdRequest())
    try{
        const response = await axios.get(
            getCaseByIdEndPoint(caseId)
        );
        
        const data = response?.data?.data;
        dispatch({
            type: UPDATE_CASES,
            payload: [data],
        });
        dispatch(getCaseByIdSuccess());
    }
    catch(error){
        console.error('Could not get actual case. ',error.message);
        dispatch(getCaseByIdFailure());
    }
};

//Set the actual case in the store
export const setActualCase = (caseId: number) => (dispatch:any) => {
    dispatch({
        type: SET_ACTUAL_CASE,
        payload: caseId,
    })
} 

