import { IQPs } from "./model/attentionQP";
import moment from "moment";

const port = process.env.REACT_APP_SERVER_PORT;
const ip = process.env.REACT_APP_SERVER_IP;
const ip_GCP = process.env.REACT_APP_GCP_IP;

export const postLoginEndPoint = (): string => {
  return `${ip}:${port}/api/v1/login`;
};

export const postLogoutEndPoint = (): string => {
  return `${ip}:${port}/api/v1/logout`;
};

export const postPasswordRestoreEndPoint = (): string => {
  return `${ip}:${port}/api/v1/users/password-reset`;
};

export const getCasesEndPoint = (queryParameters: IQPs): string => {
  let response = `${ip}:${port}/api/v1/user/cases?`;
  const endPoint = generateEndPointByQPs(queryParameters, response);
  return endPoint;
};

export const getCaseByIdEndPoint = (caseId: number): string => {
  let endpoint = `${ip}:${port}/api/v1/user/cases/${caseId}`;
  return endpoint;
};

export const getProvisionsCaseEndPoint = (idCase: number): string => {
  const url = `${ip}:${port}/api/v1/user/cases/${idCase}/provisions`;
  return url;
};

export const getAttentionsByQPsEndPoint = (queryParameters: IQPs): string => {
  let response = `${ip}:${port}/api/v1/user/attentions?`;
  const endPoint = generateEndPointByQPs(queryParameters, response);
  return endPoint;
};

export const getDateGroupAttentionsByQPsEndPoint = (
  queryParameters: IQPs
): string => {
  let response = `${ip}:${port}/api/v1/user/attentions/dateGroup/?`;
  const endPoint = generateEndPointByQPs(queryParameters, response);
  return endPoint;
};

export const getUserDataEndPoint = (): string => {
  return `${ip}:${port}/api/v1/user`;
};

export const patchAttentionsEndPoint = (idAttention: number): string => {
  const url = `${ip}:${port}/api/v1/user/attentions/${idAttention}`;
  return url;
};

export const postEvolutionEndPoint = () => {
  const url = `${ip}:${port}/api/v2/user/evolutions`;
  return url;
};

export const postEvolutionMediaEndPoint = (): string => {
  return `${ip}:${port}/api/v2/user/evolutions/media`;
};

export const postAttentionsEndPoint = (): string => {
  const url = `${ip}:${port}/api/v1/user/attentions/`;
  return url;
};

export const getCaseEvolutionsEndPoint = (idCase: number): string => {
  const url = `${ip}:${port}/api/v2/user/evolutions?caseId=${idCase}`;
  return url;
};

export const getEvolutionMediaEndPoint = (idEvolution: number): string => {
  return `${ip}:${port}/api/v2/user/evolutions/${idEvolution}/media`;
};

export const getTenantsEndPoint = (qps: IQPs): string => {
  let response = `${ip}:${port}/api/v1/tenants?`;
  const endPoint = generateEndPointByQPs(qps, response);
  return endPoint;
};

export const getTenantByIdEndPoint = (tenantCode: string): string => {
  let response = `${ip}:${port}/api/v1/tenants/${tenantCode}`;
  return response;
};

export const postEvolutionFormEndPoint = (): string => {
  const url = `${ip}:${port}/api/v2/user/forms/link`;
  return url;
};

export const getEvolutionCountEndPoint = (
  searchParams: URLSearchParams
): string => {
  return `${ip}:${port}/api/v2/user/evolutions/count?${searchParams}`;
};

export const getCategoriesEndPoint = (
  searchParams: URLSearchParams
): string => {
  const url = `${ip}:${port}/api/v2/user/evolutions/categories?${searchParams}`;
  return url;
};

export const postEvolutionGCEndPoint = (): string => {
  return `${ip_GCP}`;
};

const generateEndPointByQPs = (
  queryParameters: IQPs,
  endPoint: string
): string => {
  let response = endPoint;
  for (const [key, value] of Object.entries(queryParameters)) {
    response += getQP(key, value);
  }
  // Remove last character, it is gonna be a '&'
  response = response.substring(0, response.length - 1);
  return response;
};

const getQP = (qpName: any, qp: any) => {
  if (qp !== null && qp !== undefined) {
    if (moment.isMoment(qp)) {
      return `${qpName}=${qp.format("YYYY-MM-DD")}&`;
    } else if (Array.isArray(qp)) {
      return buildQueryString(qpName, qp);
    } else if (Number.isInteger(qp) || typeof qp === "string") {
      return `${qpName}=${qp}&`;
    }
  } else {
    return "";
  }
};

const buildQueryString = (field: any, arrayData: any) => {
  let dataString = "";
  if (arrayData) {
    dataString += `${field}=`;
    arrayData.forEach((field: any) => {
      dataString += `${field},`;
    });
    // Remove last character, it is gonna be a ','
    dataString = dataString.substring(0, dataString.length - 1);
    dataString += "&";
  }
  return dataString;
};
