export const LOG_OUT = 'LOG_OUT';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_SESSION = 'SET_SESSION';
export const SET_CASES = 'SET_CASES';
export const SET_ACTUAL_CASE = 'SET_ACTUAL_CASE';
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_ATTENTIONS = "SET_ATTENTIONS";
export const SET_PROVISIONS = "SET_PROVISIONS";
export const UPDATE_CASES = "UPDATE_CASES";
export const UPDATE_EVOLUTIONS = "UPDATE_EVOLUTIONS";
export const UPDATE_PROVISIONS = "UPDATE_PROVISIONS";
export const UPDATE_ATTENTIONS = "UPDATE_ATTENTIONS";
export const UPDATE_ATTENTION = "UPDATE_ATTENTION";
export const SET_HEADER = "SET_HEADER";
export const SET_TENANT = "SET_TENANT";
export const SET_EVOLUTION_MEDIA = "SET_EVOLUTION_MEDIA";

//Request Actions
export const GET_CASES_REQUEST = "GET_CASES_REQUEST";
export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST";
export const GET_CASE_BY_ID_REQUEST = "GET_CASE_BY_ID_REQUEST";
export const UPDATE_ATTENTIONS_REQUEST = "UPDATE_ATTENTIONS_REQUEST";
export const POST_ATTENTION_REQUEST = "POST_ATTENTION_REQUEST";
export const GET_PROVISIONS_REQUEST = "GET_PROVISIONS_REQUEST";
export const GET_TENANT_REQUEST = "GET_TENANT_REQUEST";
export const POST_LOGIN_REQUEST = "POST_LOGIN_REQUEST";
export const GET_USER_DATA_REQUEST = "GET_USER_DATA_REQUEST";
export const POST_LOGOUT_REQUEST = "POST_LOGOUT_REQUEST";
export const POST_RESTORE_PASSWORD_REQUEST = "POST_RESTORE_PASSWORD_REQUEST";
export const PATCH_ATTENTION_REQUEST = "PATCH_ATTENTION_REQUEST";
export const POST_EVOLUTION_FORM_REQUEST = "POST_EVOLUTION_FORM_REQUEST";
export const POST_GFORM_EVOLUTION_REQUEST = "POST_GFORM_EVOLUTION_REQUEST";
export const POST_EVOLUTION_REQUEST = "POST_EVOLUTION_REQUEST";
export const GET_ATTENTIONS_BY_QPS_REQUEST = "GET_ATTENTIONS_BY_QPS_REQUEST";
export const UPDATE_EVOLUTIONS_REQUEST = "UPDATE_EVOLUTIONS_REQUEST";
export const GET_EVOLUTION_PHOTO_REQUEST = "GET_EVOLUTION_PHOTO_REQUEST";

//Success Actions
export const GET_CASES_SUCCESS = "GET_CASES_SUCCESS";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CASE_BY_ID_SUCCESS = "GET_CASE_BY_ID_SUCCESS";
export const UPDATE_ATTENTIONS_SUCCESS = "UPDATE_ATTENTIONS_SUCCESS";
export const POST_ATTENTION_SUCCESS = "POST_ATTENTION_SUCCESS";
export const GET_PROVISIONS_SUCCESS = "GET_PROVISIONS_SUCCESS";
export const GET_TENANT_SUCCESS = "GET_TENANT_SUCCESS";
export const POST_LOGIN_SUCCESS = "POST_LOGIN_SUCCESS";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const POST_LOGOUT_SUCCESS = "POST_LOGOUT_SUCCESS";
export const POST_RESTORE_PASSWORD_SUCCESS = "POST_RESTORE_PASSWORD_SUCCESS";
export const PATCH_ATTENTION_SUCCESS = "PATCH_ATTENTION_SUCCESS";
export const POST_EVOLUTION_FORM_SUCCESS = "POST_EVOLUTION_FORM_SUCCESS";
export const POST_GFORM_EVOLUTION_SUCCESS = "POST_GFORM_EVOLUTION_SUCCESS";
export const POST_EVOLUTION_SUCCESS = "POST_EVOLUTION_SUCCESS";
export const GET_ATTENTIONS_BY_QPS_SUCCESS = "GET_ATTENTIONS_BY_QPS_SUCCESS";
export const UPDATE_EVOLUTIONS_SUCCESS = "UPDATE_EVOLUTIONS_SUCCESS";
export const GET_EVOLUTION_PHOTO_SUCCESS = "GET_EVOLUTION_PHOTO_SUCCESS";

//Failure Actions
export const GET_CASES_FAILURE = "GET_CASES_FAILURE";
export const GET_CATEGORIES_FAILURE = "GET_CATEGORIES_FAILURE";
export const GET_CASE_BY_ID_FAILURE = "GET_CASE_BY_ID_FAILURE";
export const UPDATE_ATTENTIONS_FAILURE = "UPDATE_ATTENTIONS_FAILURE";
export const POST_ATTENTION_FAILURE = "POST_ATTENTION_FAILURE";
export const GET_PROVISIONS_FAILURE = "GET_PROVISIONS_FAILURE";
export const GET_TENANT_FAILURE = "GET_TENANT_FAILURE";
export const POST_LOGIN_FAILURE = "POST_LOGIN_FAILURE";
export const GET_USER_DATA_FAILURE = "GET_USER_DATA_FAILURE";
export const POST_LOGOUT_FAILURE = "POST_LOGOUT_FAILURE";
export const POST_RESTORE_PASSWORD_FAILURE = "POST_RESTORE_PASSWORD_FAILURE";
export const PATCH_ATTENTION_FAILURE = "PATCH_ATTENTION_FAILURE";
export const POST_EVOLUTION_FORM_FAILURE = "POST_EVOLUTION_FORM_FAILURE";
export const POST_GFORM_EVOLUTION_FAILURE = "POST_GFORM_EVOLUTION_FAILURE";
export const POST_EVOLUTION_FAILURE = "POST_EVOLUTION_FAILURE";
export const GET_ATTENTIONS_BY_QPS_FAILURE = "GET_ATTENTIONS_BY_QPS_FAILURE";
export const UPDATE_EVOLUTIONS_FAILURE = "UPDATE_EVOLUTIONS_FAILURE";
export const GET_EVOLUTION_PHOTO_FAILURE = "GET_EVOLUTION_PHOTO_FAILURE";

//close snackbar Actions
export const GET_CASES_CLOSE = "GET_CASES_CLOSE";
export const GET_CATEGORIES_CLOSE = "GET_CATEGORIES_CLOSE";
export const GET_CASE_BY_ID_CLOSE = "GET_CASE_BY_ID_CLOSE";
export const UPDATE_ATTENTIONS_CLOSE = "UPDATE_ATTENTIONS_CLOSE";
export const POST_ATTENTION_CLOSE = "POST_ATTENTION_CLOSE";
export const GET_PROVISIONS_CLOSE = "GET_PROVISIONS_CLOSE";
export const GET_TENANT_CLOSE = "GET_TENANT_CLOSE";
export const POST_LOGIN_CLOSE = "POST_LOGIN_CLOSE";
export const GET_USER_DATA_CLOSE = "GET_USER_DATA_CLOSE";
export const POST_LOGOUT_CLOSE = "POST_LOGOUT_CLOSE";
export const POST_RESTORE_PASSWORD_CLOSE = "POST_RESTORE_PASSWORD_CLOSE";
export const PATCH_ATTENTION_CLOSE = "PATCH_ATTENTION_CLOSE";
export const POST_EVOLUTION_FORM_CLOSE = "POST_EVOLUTION_FORM_CLOSE";
export const POST_GFORM_EVOLUTION_CLOSE = "POST_GFORM_EVOLUTION_CLOSE";
export const POST_EVOLUTION_CLOSE = "POST_EVOLUTION_CLOSE";
export const GET_ATTENTIONS_BY_QPS_CLOSE = "GET_ATTENTIONS_BY_QPS_CLOSE";
export const GET_CASE_EVOLUTIONS_CLOSE = "GET_CASE_EVOLUTIONS_CLOSE";
export const GET_EVOLUTION_PHOTO_CLOSE = "GET_EVOLUTION_PHOTO_CLOSE";
