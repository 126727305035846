import { utcToZonedTime } from "date-fns-tz";
import moment from "moment";

export default function mapFormData(form: any) {
  let mappedForm = {
    ...form,
    formSections: form?.formSections?.map?.((formSection: any) => {
      let formData = JSON.parse(formSection?.formData);

      formData.properties = Object.keys(formData?.properties).reduce(
        (accum: any, key: string) => {
          let info = formData?.properties?.[key];

          if (info?.format === "date-time" || info?.format === "date") {
            let { default: defaultValue = null, ...rest } = info;

            accum[key] = rest;

            if (!!defaultValue) {
              accum[key].default =
                accum[key].format === "date-time"
                  ? moment(defaultValue).format("DD/MM/YYYY HH:mm")
                  : moment(defaultValue).format("DD/MM/YYYY");

              /* utcToZonedTime(
                new Date(defaultValue).toUTCString(),
                Intl?.DateTimeFormat?.().resolvedOptions?.()?.timeZone
              ); */
            }
          } else {
            accum[key] = info;
          }

          return accum;
        },
        {}
      );

      return {
        ...formSection,
        formData,
      };
    }),
  };

  return mappedForm;
}
