enum EVOLUTION_TYPE {
    NORMAL = 1,
    ABSENT_PATIENT = 2,
    NOT_GOING = 3,
    CRITICAL = 4,
    VIDEOCALL = 9,
    DISCHARGE = 10,
 }
 
 export default EVOLUTION_TYPE;
 