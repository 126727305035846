import Ajv from "ajv";
import localize from "ajv-i18n";
// import addFormats from "ajv-formats";

let ajv = new Ajv({
  allErrors: true,
  useDefaults: true,
  $data: true,
});

ajv.addKeyword("uniforms");
ajv.addKeyword("allowedValues");

// Tech-Debt: quick workaround to make the validation work
ajv.addFormat("date", (value) => true);
ajv.addFormat("date-time", (value) => true);

// addFormats(ajv);

export default function createValidator(schema: object) {
  let validator = ajv.compile(schema);

  return (model: object) => {
    validator(model);

    if (validator.errors?.length) {
      // Translate error to spanish locale
      localize.es(validator.errors);

      return {
        details: validator.errors,
      };
    }

    return null;
  };
}
