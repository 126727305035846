import React, { useEffect, useState } from 'react';
import { CardHeader, InputLabel, Snackbar, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FlexView from 'react-flexview';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { connect, useDispatch } from 'react-redux';
import { setSession, setUserData, setTenant } from '../../redux/actions/userActions';
import { ROUTES } from '../../routes/routes';
import {
  POST_LOGIN_BODY,
} from '../../redux/actions/userActions';
import { USER } from '../../shared/utils/model/user';
import LoginButton from './components/login-button/login-button';
import PasswordRestore from './components/PasswordRestore/PasswordRestore';
import styles from './Login.module.css';
import SimfinixLogo from '../../assets/Simfinix/logotext.png';
import Tervey_logotipo from '../../assets/Tervey/Tervey_logotipo.png';
import { postLoginClose } from '../../redux/actions/closeActions';
import { TENANT } from '../../shared/utils/model/tenant';
import { Alert } from '@material-ui/lab';
import pjson from './../../../package.json';
import { requestStateSelector } from '../../redux/reducers/requestStateReducer';

interface props{
  user: USER,
  requestState: string,
  history: any
  setUserData: any,
  setSession: any,
  setTenant: any,
  postLoginClose: any,
}

const Login = ({
  user,
  requestState, 
  history, 
  setUserData, 
  setSession, 
  setTenant,
  postLoginClose}: props) => {

  const [form, setValues] = useState({
    username: '',
    password: '',
  })

  const [passVisibility, setPassVisibility] = useState(false);
  const [checked, setChecked] = useState(false);
  const [restore, setRestore] = useState(false);
  const [emailRestore, setEmailRestore] = useState('');
  const [disableLogin, setDisableLogin] = useState(false);
  const [error, setError] = useState(false);
  const logo = user.tenant?.logo;

  const dispatch = useDispatch();

  useEffect(() => {
    
    const getSubdomain = () => {
      let index = 0;
      const full = window.location.hostname;
      const parts = full.split('.');
      let subdomain;
      if (!!parts[index] && parts[index] !== 'www') {
        subdomain = parts[index]
      } else {
        index += 1;
        if (!!parts[index]) {
          subdomain = parts[index]
        }
      }
      return subdomain || '';
    }
    
    if (!!user.tenant == false){
      let tenantCode = getSubdomain();
      if (!tenantCode || tenantCode === "prestadores" || tenantCode === "localhost") {
        tenantCode = "adomicilio"
      }
      setTenant(tenantCode);
    }
  }, []);


  const handleInput = (event: { target: { name: any; value: any; }; }) => {
    setValues({
      ...form,
      [event.target.name]: event.target.value
    })
  }

  const handleClickEraseUsername = () => {
    setValues({
      ...form,
      username: '',
    });
  }

  const handleMouseDownUsername = (event: any) => {
    event.preventDefault();
  }

  const handleClickShowPassword = () => {
    setPassVisibility(!passVisibility);
  }

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  }

  const validateEmailInput = () => {
    return (new RegExp(
      // tslint:disable-next-line: max-line-length
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    ).test(form.username))
  }


  const handleSubmit = async (event: { preventDefault: () => void; }) => {

    setDisableLogin(true);
    event.preventDefault();
    
    //if(validateEmailInput()){  
      const body: POST_LOGIN_BODY = {
        username: form.username,
        password: form.password,
        applicationId: '3',
        tenantCode: ((user?.tenant as TENANT).id as string)
      }
      setSession(body).then((result: string) => {
        if(result)
          setUserData().then((result: string) => {
            history.push(ROUTES.home);
          })
        else{
          setDisableLogin(false);
        }
      })
    //}
    // else { 
    //   // Invalid email
    //   console.error("Email invalido");
    //   setError(true);
    // }
  }

  const handleForgot = () => {
    setRestore(!restore);
  }

  const handleRestoreChange = (event: { target: { value: any; }; }) => {
    setEmailRestore(event.target.value);
  }

  const handleSnackbarClose = () => {
    if(requestState!='REQUEST')
      dispatch(postLoginClose());
  }

  return (
    <section className="login">
      <FlexView
        wrap
      >
        <Hidden smDown>

          <FlexView
            column
            vAlignContent='bottom'
            wrap
            className={styles.imgOverlaySide}
          //grow={1}
          >
            {/* <img 
              src={imagen}
              className={styles.imgOverlay}
            /> */}
            {/* <FlexView></FlexView> */}
            <FlexView
              vAlignContent='center'
              hAlignContent='center'
              className={styles.tenantContainer}
            >
              <img
                src={!!logo ? logo : Tervey_logotipo}
                className={styles.tenantLogo}
              />
            </FlexView>
            <FlexView
              column
              vAlignContent='center'
              hAlignContent='center'
              className={styles.bottomContainer}
            >
              <FlexView
                vAlignContent='center'
                hAlignContent='center'
              >
                <b className={styles.poweredString}>Powered by</b>
                <img
                  src={SimfinixLogo}
                  className={styles.simfinixLogo}
                />
              </FlexView>
              <span className={styles.poweredString}>v.{pjson.version}</span>
            </FlexView>
          </FlexView>
        </Hidden>
        <FlexView column grow={2}>
          {/* LOGIN CARD */}
          {
            (!restore) ?
              <FlexView hAlignContent='center' vAlignContent='center' grow={3}>
                <div className={styles.loginCard}>
                  <Card>
                    <CardHeader
                      style={{ textAlign: 'center' }}
                      title={<Typography color='secondary' variant='h5'>Bienvenido</Typography>}
                      subheader={"Ingrese sus datos para iniciar sesion"}
                    // avatar={<AccountCircleIcon color='primary' className={styles.iconLogin}/>}
                    />
                    <CardContent>
                      <form className="login__container--form" onSubmit={handleSubmit}>
                        <div>
                          <FormControl style={{ width: '100%' }}>
                            <InputLabel
                              error={error}
                              htmlFor="standard-adornment-username"
                            >
                              Ingrese su nombre de usuario
                            </InputLabel>
                            <Input
                              error={error}
                              name="username"
                              color="primary"
                              id="standard-adornment-username"
                              type='text'
                              value={form.username}
                              onChange={handleInput}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    color="primary"
                                    onClick={handleClickEraseUsername}
                                    onMouseDown={handleMouseDownUsername}
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                        </div>
                        <div>
                          <FormControl style={{ width: '100%' }}>
                            <InputLabel 
                              error={error}
                              htmlFor="standard-adornment-password"
                            >
                              Ingrese su contraseña
                            </InputLabel>
                            <Input
                              error={error}
                              name="password"
                              color='primary'
                              id="standard-adornment-password"
                              type={passVisibility ? 'text' : 'password'}
                              value={form.password}
                              onChange={handleInput}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    color='primary'
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {passVisibility ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                        </div>

                        <LoginButton 
                          label={'Ingresar'} 
                          disabled={disableLogin || form.password == '' || form.username == ''} 
                          type='submit'
                          onClick={handleSubmit} 
                        />
                        <div className="login__container--remember-me" style={{ textAlign: 'center' }}>
                          <Button color='primary' onClick={handleForgot} >Olvide mi contraseña</Button>
                        </div>
                      </form>
                    </CardContent>
                  </Card>
                </div>
              </FlexView>
              : <PasswordRestore 
                  tenantId={user.tenant?.id} 
                  handleForgot={handleForgot} 
                />
          }
          <Hidden mdUp>
            <FlexView
              vAlignContent='center'
              hAlignContent='center'
              className={styles.tenantContainerMobile}
            >
              <img
                src={!!logo ? logo : Tervey_logotipo}
                className={styles.tenantLogoMobile}
              />
            </FlexView>
            <FlexView
              column
              vAlignContent='center'
              hAlignContent='center'
              className={styles.bottomContainer}
            >
              <FlexView
                vAlignContent='center'
                hAlignContent='center'
              >
                <b className={styles.poweredStringMobile}>Powered by</b>
                <img
                  src={SimfinixLogo}
                  className={styles.simfinixLogo}
                />
              </FlexView>
              <span style={{ color: 'grey', opacity: 1 }}>v.{pjson.version}</span>
            </FlexView>
          </Hidden>
        </FlexView>
      </FlexView>

      <Snackbar open={requestState=='FAILURE'} autoHideDuration={4000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="error">
          Usuario o contraseña incorrecta
        </Alert>
      </Snackbar>
    </section>
  );
}

const mapDispatchToProps = {
  setUserData,
  setSession,
  setTenant,
  postLoginClose
}

const mapStatesToProps = (state: any, props: any) => {
  const user: USER = state.user;
  const requestState = requestStateSelector('POST_LOGIN_', state);
  return { 
    user,
    requestState,
  }
}

export default connect(mapStatesToProps, mapDispatchToProps)(Login);
