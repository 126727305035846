import axios, { AxiosResponse } from "axios";
import {
  getAttentionsByQPsEndPoint,
  patchAttentionsEndPoint,
  postAttentionsEndPoint,
} from "../../shared/utils/apis";
import ATTENTION_STATE from "../../shared/utils/enums/attentionState";
import { ATTENTION } from "../../shared/utils/model/attention";
import { IQPs } from "../../shared/utils/model/attentionQP";
import { HTTP_RESPONSE } from "../../shared/utils/model/http";
import {
  SET_ATTENTIONS,
  UPDATE_ATTENTIONS,
  UPDATE_ATTENTION,
} from "../action_types";
import {
  getAttentionsByQPSFailure,
  patchAttentionFailure,
  postAttentionFailure,
  updateAttentionFailure,
} from "./failureActions";
import {
  getAttentionsByQPSRequest,
  patchAttentionRequest,
  postAttentionRequest,
  updateAttentionRequest,
} from "./requestActions";
import {
  getAttentionsByQPSSuccess,
  patchAttentionSuccess,
  postAttentionSuccess,
  updateAttentionSuccess,
} from "./successActions";

export const setAttentions = (payload: any) => ({
  type: SET_ATTENTIONS,
  payload,
});

export const updateAttentions = (caseId: number) => async (dispatch: any) => {
  dispatch(updateAttentionRequest());
  let attentions: ATTENTION[] = [];
  try {
    const response = await axios.get(
      getAttentionsByQPsEndPoint({
        casesIds: [caseId],
        expand: ["practice", "patient"],
        statesIds: [
          ATTENTION_STATE.REGISTERED,
          ATTENTION_STATE.DONE,
          ATTENTION_STATE.NOT_DONE,
          ATTENTION_STATE.IN_PROGRESS,
          ATTENTION_STATE.DEBITED,
          ATTENTION_STATE.PAYABLE,
        ],
        size: 100,
        page: 0,
      }),
      { timeout: 60000 }
    );
    attentions = response?.data?.data;
    dispatch({
      type: UPDATE_ATTENTIONS,
      payload: attentions,
    });
    dispatch(updateAttentionSuccess());
  } catch (error) {
    console.info("Error updating attentions data", error.message);
    dispatch(updateAttentionFailure());
  }
};

export const updateAttention = (attention: any) => async (dispatch: any) => {
  dispatch({
    type: UPDATE_ATTENTION,
    payload: attention,
  });
};

export const postAttention =
  (
    agreementId: any,
    fromDate: any,
    quantity: any,
    id: number,
    toDate?: any,
    description?: any
  ) =>
  async (dispatch: any) => {
    dispatch(postAttentionRequest());
    try {
      const response = await axios.post(postAttentionsEndPoint(), [
        {
          fromDate,
          toDate,
          state: {
            id: 1,
          },
          agreementId,
          dateInReal: fromDate,
          dateOutReal: toDate,
          quantity,
          evolutionText: description,
        },
      ]);
      dispatch(postAttentionSuccess());
      return response.data;
    } catch (error) {
      console.error("Could not create attention. ", error.message);
      dispatch(postAttentionFailure());
    }
  };

export const patchAttention =
  (
    attentionId: any,
    fromDate: any,
    toDate: any,
    quantity: any,
    stateId: any = 3
  ) =>
  async (dispatch: any) => {
    dispatch(patchAttentionRequest());
    try {
      const responsePatch = await axios.patch(
        patchAttentionsEndPoint(attentionId),
        {
          checkIn: fromDate,
          checkOut: toDate,
          fromDate,
          state: {
            id: stateId,
          },
          dateInReal: fromDate,
          dateOutReal: toDate,
          quantity,
          coordinates: {
            accuracy: 0,
            altitude: 0,
            heading: 0,
            latitude: 0,
            longitude: 0,
            speed: 0,
          },
        }
      );
      dispatch(patchAttentionSuccess());
    } catch (error) {
      console.error("Could not patch the attenton. ", error.message);
      dispatch(patchAttentionFailure());
      throw error;
    }
  };

export const getAttentionsByQPS =
  (fromDate: moment.Moment, toDate: moment.Moment) => async (dispatch: any) => {
    let attentions: ATTENTION[] = [];
    let currentPage = 0;
    let maxPages = 0;
    dispatch(getAttentionsByQPSRequest());
    try {
      do {
        const body: IQPs = {
          fromDate,
          toDate,
          expand: ["practice", "patient"],
          zoneId: "-03:00",
          size: 100, // Estaba en 100
          page: currentPage,
        };
        const response = await axios.get(getAttentionsByQPsEndPoint(body), {
          timeout: 60000,
        });
        maxPages = response.data.pagination.pages;
        currentPage++;
        attentions = response?.data?.data;
        dispatch({
          type: UPDATE_ATTENTIONS,
          payload: attentions,
        });
      } while (currentPage < maxPages);
      dispatch(getAttentionsByQPSSuccess());
      return attentions;
    } catch (error) {
      console.info("Error updating attentions data. ", error.message);
      dispatch(getAttentionsByQPSFailure());
    }
  };
