import { useState } from "react";
import TableRow from "@material-ui/core/TableRow";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import ListIcon from "@material-ui/icons/List";
import { Chip, IconButton, TableCell } from "@material-ui/core";

import styles from "./DailyAttentionRow.module.css";
import EvolveAttentionDialog from "../../../case/components/AttentionDialogs/EvolveAttentionDialog";
import CancelAttentionDialog from "../../../case/components/AttentionDialogs/CancelAttentionDialog";
import { postEvolutionForm } from "../../../../redux/actions/evolutionActions";
import moment from "moment";
import { ATTENTION } from "../../../../shared/utils/model/attention";
import { connect } from "react-redux";

const color = [
  "white", // no hay id 0 en el estado
  "#a7c6b9",
  "#5FE8D4",
  "#61F27E",
  "#E89A5F",
  "#B85FE8",
  "#F0E662",
  "#D7B1F2",
  "#7C5DE3",
  "#E6684C",
];

interface props {
  attention: ATTENTION;
  onClick: any;
  postEvolutionForm: any;
  onAttentionChanged: () => void;
}

const DailyAttentionRow = ({
  attention,
  onClick,
  postEvolutionForm,
  onAttentionChanged,
}: props) => {
  const [currentAttention, setCurrentAttention] = useState(attention);
  const [evolveDialogIsOpen, setEvolveDialogIsOpen] = useState(false);
  const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState(false);
  const [formResponse, setFormResponse] = useState({});
  const [isResponse, setIsResponse] = useState(false);

  const _fromDate = moment(attention.fromDate).toDate();
  const dateIn = new Date(_fromDate);

  const handleClickOpen = async () => {
    if (!isResponse) await getEvolutionForm();
    setEvolveDialogIsOpen(true);
  };

  const handleClickCancel = async () => {
    setCancelDialogIsOpen(true);
  };

  const handleClose = () => {
    setEvolveDialogIsOpen(false);
    setCancelDialogIsOpen(false);
  };

  const getEvolutionForm = async () => {
    try {
      const response = await postEvolutionForm(attention.id);
      if (response.data !== null) {
        setFormResponse({ ...response.data });
        setIsResponse(true);
      }
    } catch (error) {
      console.error("Error al solicitar formulario: ", error);
    }
  };

  const formatDate = (date: Date) => {
    if (!!date) {
      let _date: any = date.getDate();
      if (_date < 10) {
        _date = "0" + _date;
      }
      let _monthFrom: any = dateIn.getMonth() + 1;
      if (_monthFrom < 10) {
        _monthFrom = "0" + _monthFrom;
      }
      let _dateIn = moment(dateIn);
      let _hoursFrom: any = _dateIn.hour();
      if (_hoursFrom < 10) {
        _hoursFrom = "0" + _hoursFrom;
      }
      let _minutesFrom: any = _dateIn.minutes();
      if (_minutesFrom < 10) {
        _minutesFrom = "0" + _minutesFrom;
      }
      return (
        _date +
        "/" +
        _monthFrom +
        "/" +
        dateIn.getFullYear() +
        " " +
        _hoursFrom +
        ":" +
        _minutesFrom
      );
    } else {
      return "";
    }
  };
  
  const handleAttentionChanged = (attention: any) => {
    setCurrentAttention(attention);
    onAttentionChanged();
  };

  return (
    <>
      <TableRow className={styles.tableRow}>
        <TableCell>
          {formatDate(dateIn)}
          {!!currentAttention.toDate &&
            "-" +
              formatDate(new Date(moment(currentAttention.toDate).toDate()))}
        </TableCell>
        <TableCell>
          {currentAttention.patient.name} {currentAttention.patient.surname}
        </TableCell>
        <TableCell>{currentAttention.practice.name}</TableCell>
        <TableCell>
          <Chip
            size="small"
            label={currentAttention.state.name}
            style={{
              backgroundColor: color[currentAttention.state.id],
              color: "white",
              width: "80px",
            }}
          />
        </TableCell>
        <TableCell style={{ padding: "0px" }}>
          {(currentAttention.state.name === "Agendada" ||
            currentAttention.state.name === "En Curso") && (
            <IconButton onClick={handleClickCancel}>
              <CancelIcon color="error" />
            </IconButton>
          )}
          {(currentAttention.state.name === "Agendada" ||
            currentAttention.state.name === "En Curso" ||
            currentAttention.state.name === "Realizada") && (
            <IconButton
              style={{
                marginLeft:
                  currentAttention.state.name === "Realizada" ? "28px" : "6px",
              }}
              onClick={handleClickOpen}
            >
              <AddCircleIcon color="primary" />
            </IconButton>
          )}
        </TableCell>
        <TableCell>
          <IconButton style={{ padding: "0px" }} onClick={onClick}>
            <ListIcon color="primary" />
          </IconButton>
        </TableCell>
      </TableRow>

      <EvolveAttentionDialog
        attention={currentAttention}
        open={evolveDialogIsOpen}
        showDescription={!isResponse}
        formData={formResponse}
        onAttentionChanged={handleAttentionChanged}
        handleClose={handleClose}
      />

      <CancelAttentionDialog
        attention={currentAttention}
        open={cancelDialogIsOpen}
        onSuccess={handleAttentionChanged}
        handleClose={handleClose}
      />
    </>
  );
};

const mapDispatchToProps = {
  postEvolutionForm,
};

export default connect(null, mapDispatchToProps)(DailyAttentionRow);
