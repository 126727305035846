import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import { Box, TableRow, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import styles from './DailyAtentionsList.module.css';
import { updateAttentions } from '../../../../redux/actions/attentionActions';
import { ATTENTION } from '../../../../shared/utils/model/attention';
import WaitingAnimation from '../../../../shared/modules/WaitingAnimation/WaitingAnimation';
import DailyAttentionRow from '../DailyAttentionRow/DailyAttentionRow';
import moment from 'moment';
import { IQPs } from '../../../../shared/utils/model/attentionQP';
import ATTENTION_STATE from '../../../../shared/utils/enums/attentionState';
import { useHistory } from 'react-router-dom';
import { requestStateSelector } from '../../../../redux/reducers/requestStateReducer';


const Headers = [
    'Entrada / Salida',
    'Paciente',
    'Especialidad',
    'Estado',
    'Evolucionar',
    'Detalles',
]

// agendada -> En Curso -> Realizada - No Realizada
// Verde -> Naranja -> Azul -> gris - No Realizada
const color = [
    'white', // no hay id 0 en el estado
    '#a7c6b9',
    '#5FE8D4',
    '#61F27E',
    '#E89A5F',
    '#B85FE8',
    '#F0E662',
    '#D7B1F2',
    '#7C5DE3',
    '#E6684C'
]

// esa: export const ATTENTIONS_COLORS = {
//     [AttentionStates.AGENDADA]: '#A7C6B9',
//     [AttentionStates.REALIZADA]: '#61F27E',
//     [AttentionStates.EN_CURSO]: '#5FE8D4',
//     [AttentionStates.NO_REALIZADA]: '#E89A5F',
//     [AttentionStates.INFORMADA]: '#7C5DE3',
//     [AttentionStates.FACTURABLE]: '#B85FE8',
//     [AttentionStates.EN_PROCESO]: '#D7B1F2',
//     [AttentionStates.NO_INFORMADA]: '#E6684C',
//     [AttentionStates.DEBITADA]: '#F0E662',
//  }

interface props {
  attentions: { [key: string]: ATTENTION };
  day: string;
  requestState: string;
  onAttentionChanged: () => void;
}

const DailyAtentionsList = ({
  attentions,
  day,
  requestState,
  onAttentionChanged,
}: props) => {
  //Deberia ver una bandera de carga del fetch x dia
  //const [loading, setLoading] = useState(true);
  const history = useHistory();

  const handleAttentionClick = (caseId: number) => {
    history.push(`/cases/${caseId}`);
  };

  const newDate = new Date();
  const today = `${newDate.getDate()}/${
    newDate.getMonth() + 1
  }/${newDate.getFullYear()}`;

  return (
    <>
      <Card>
        <CardHeader
          style={{ textAlign: "center" }}
          title={
            <Typography variant="h6" color="secondary">
              Lista de Atenciones:{" "}
              {day ? new Date(`${day} 00:00`).toLocaleDateString() : today}
            </Typography>
          }
        />
        {requestState != "REQUEST" ? (
          <CardContent>
            <Paper elevation={3}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {Headers.map((header, i) => {
                        return (
                          <TableCell key={i}>
                            <b>{header}</b>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {/* Informacion de la lista */}
                    {Object.keys(attentions).map((key: string, i: any) => {
                      return (
                        <DailyAttentionRow
                          key={i}
                          attention={attentions[key]}
                          onAttentionChanged={onAttentionChanged}
                          onClick={() => {
                            handleAttentionClick(attentions[key].caseId);
                          }}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {Object.keys(attentions).length == 0 ? (
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  className={styles.noAttCont}
                >
                  <Grid item className={styles.noAttText}>
                    <b>No se han encontrado atenciones</b>
                  </Grid>
                </Grid>
              ) : null}
            </Paper>
            {/* <WaitingAnimation
                    {/* <WaitingAnimation
            {/* <WaitingAnimation
                        height={75}
                        width={75}
                    />     */}
          </CardContent>
        ) : (
          <Box>
            <WaitingAnimation width={75} height={75} />
          </Box>
        )}
      </Card>
    </>
  );
};


const mapDispatchToProps = {
    updateAttentions,
}

const mapStatesToProps = (state: any, props: any) => {
    const requestState = requestStateSelector('GET_ATTENTIONS_BY_QPS_', state);
    return {
        requestState
    };
}

export default connect(mapStatesToProps, null)(DailyAtentionsList);
