import React from "react";
import { format, parse } from "date-fns";
import esLocale from "date-fns/locale/es";
import { useTheme } from "@material-ui/core";
import { DatePicker, DatePickerProps } from "@material-ui/pickers";
import { connectField, FieldProps, filterDOMProps } from "uniforms";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import moment from "moment";

export type DateFieldProps = FieldProps<string, DatePickerProps>;

function DateField({
  name,
  label,
  value,
  placeholder,
  disabled,
  error,
  errorMessage,
  showInlineError,
  readOnly,
  helperText,
  InputLabelProps,
  onChange,
  ...rest
}: DateFieldProps) {
  let theme = useTheme();
  let themeProps = theme.props?.MuiTextField;

  let uniformsProps = rest?.field?.uniforms ?? {};
  let dateFormat = uniformsProps?.format ?? "dd/MM/yyyy";

  let inputValue: ParsableDate = !!value ? value : null;

  if (typeof inputValue === "string") {
    inputValue = moment(inputValue).utc().format(dateFormat.toUpperCase());
    /* inputValue = parse(inputValue, dateFormat, new Date(), {
      locale: esLocale,
    }); */
  }

  return (
    <DatePicker
      disabled={disabled}
      error={!!error}
      format={dateFormat}
      fullWidth={themeProps?.fullWidth ?? true}
      helperText={(error && showInlineError && errorMessage) || helperText}
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps,
      }}
      inputProps={{
        readOnly,
        ...(themeProps?.inputProps ?? {}),
      }}
      margin={themeProps?.margin ?? "dense"}
      name={name}
      label={label}
      value={inputValue}
      placeholder={placeholder}
      onChange={(value: Date) => {
        let dateString = format(value, dateFormat, {
          locale: esLocale,
        });
        onChange(dateString);
      }}
      {...filterDOMProps(rest)}
      {...uniformsProps}
    />
  );
}

export default connectField<DateFieldProps>(DateField, {
  initialValue: false,
  kind: "leaf",
});
