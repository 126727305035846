import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { PROVISION } from '../../../../shared/utils/model/provision';
import styles from './ProvisionsList.module.css';
import WaitingAnimation from '../../../../shared/modules/WaitingAnimation/WaitingAnimation';
import Agreements from './Agreements';
import { requestStateSelector } from '../../../../redux/reducers/requestStateReducer';

interface props {
    provisions: {[key: string]: PROVISION},
    requestState: string,
}

const Headers = [
    'Prestacion',
    'Frecuencia',
]

const ProvisionsList = ({ provisions, requestState: requestState }: props) => {
    
    return (
        <Card>
            <CardHeader
                style={{textAlign: 'center'}}
                title={
                    <Typography variant='h6' color='secondary'>
                        Esquema Prestacional
                    </Typography>}
            />
            <CardContent>
                <Paper elevation={3}>
                    <Grid container className={styles.header}>
                    {
                        Headers.map((header, i) => {
                            return(
                                <Grid key={i} item sm={5} md={4} className={styles.tableHeader}>
                                    <b>{header}</b>
                                </Grid>
                            );
                        })
                    }
                    </Grid>
                    {/* Informacion de la lista */}
                    {
                        (requestState!='REQUEST') ?
                            Object.keys(provisions).map((key: string, i: any) => {
                                return(
                                    <Accordion key={i} className={styles.tableRow} classes={{expanded: styles.tableRowExpanded}}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Grid container>
                                                <Grid item sm={6} md={4} style={{color: '#616161'}}>{provisions[key]?.practice?.name}</Grid>
                                                <Grid item sm={6} md={4} style={{color: '#616161'}}>{!!provisions[key]?.frequency?.amount ? provisions[key]?.frequency?.amount+'/'+provisions[key]?.frequency?.unit : '-'}</Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        {
                                            (!!provisions && !! provisions[key].agreements) ?
                                            <Agreements 
                                                agreements={provisions[key].agreements}
                                                restriction={provisions[key].practice?.restriction}/>
                                            :<Grid container justify='center' className={styles.noAttCont}>
                                                <Grid item className={styles.noAttText}><b>No se han encontrado acuerdos</b></Grid>
                                            </Grid>
                                        }
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })
                            :   <WaitingAnimation
                                    width={75}
                                    height={75}
                                />
                    }
                </Paper>
            </CardContent>
        </Card>
    );
}

const mapDispatchToProps = null;
 
const mapStatesToProps = (state: any, props: any) => {
    const provisions: {[key: string]: PROVISION} = state.provisions;
    const actualCase: number = state.actualCase;
    const requestState = requestStateSelector('GET_PROVISIONS_', state);
    return {
        provisions,
        actualCase,
        requestState,
    };
}
 
 export default connect(mapStatesToProps, mapDispatchToProps)(ProvisionsList);