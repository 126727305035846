import React, { useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import esLocale from '@fullcalendar/core/locales/es';
import interactionPlugin from "@fullcalendar/interaction";
import { getAttentionsByQPS } from '../../../../redux/actions/attentionActions';
import moment from 'moment';
import { ATTENTION } from '../../../../shared/utils/model/attention';
import WaitingAnimation from '../../../../shared/modules/WaitingAnimation/WaitingAnimation';
import DailyAttentionsList from '../DailyAttentionsList/DailyAttentionsList';
import { updateAttentions } from '../../../../redux/actions/attentionActions';
import { connect } from 'react-redux';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Button, Grid, Typography, CircularProgress } from '@material-ui/core';
import { requestStateSelector } from '../../../../redux/reducers/requestStateReducer';

import axios from "axios";
import { getDateGroupAttentionsByQPsEndPoint } from '../../../../shared/utils/apis';


const color = [
    'white', // no hay id 0 en el estado
    '#a7c6b9',
    '#5FE8D4',
    '#61F27E',
    '#E89A5F',
    '#B85FE8',
    '#F0E662',
    '#D7B1F2',
    '#7C5DE3',
    '#E6684C'
]

interface Event {
    title: string,
    date: Date,
    count: number,
    color: string,
    display: string
}

interface props {
    requestState: string,
    attentions: { [index: number]: ATTENTION },
    updateAttentions: any,
    getAttentionsByQPS: any,
}


const AttentionsCalendar = React.memo(({
    requestState,
    attentions,
    updateAttentions,
    getAttentionsByQPS }: props) => {

    var date: Date;
    var firstDay: Date;
    var lastDay: Date;
    const calendarRef = useRef<FullCalendar>(null);
    const dailyAttentions = useRef<ATTENTION[]>([]);
    const [calendarEvents, setCalendarEvents] = useState<Event[]>([]);
    /*     const [showDayList, setShowDayList] = useState(false);*/
    const [selectedDay, setSelectedDay] = useState('');
    const [showGroupAttentions, setShowGroupAttentions] = useState(false);
    const [calendarDate, setCalendarDate] = useState<Date>(new Date());
    /*     console.log('Attentions ', attentions); */

    useEffect(() => getCalendarEvents(), [calendarDate]);

    const getCalendarEvents = () => {
      firstDay = new Date(
        calendarDate.getFullYear(),
        calendarDate.getMonth(),
        1
      );
      lastDay = new Date(
        calendarDate.getFullYear(),
        calendarDate.getMonth() + 1,
        0
      );

      fetchGroupAttentionsByMonth(
        moment(
          `${firstDay.getFullYear()}-${
            firstDay.getMonth() + 1
          }-${firstDay.getDate()}`
        ),
        moment(
          `${lastDay.getFullYear()}-${
            lastDay.getMonth() + 1
          }-${lastDay.getDate()}`
        )
      );
    };

    const fetchAttentionsByDay = async (
      fromDate: moment.Moment,
      toDate: moment.Moment
    ) => {
      try {
        let atts = [];
        atts = await getAttentionsByQPS(fromDate, toDate);
        return atts;
      } catch (error) {
        console.info("Error getting  case attentions data");
        console.info(error);
      }
    };

    const fetchGroupAttentionsByMonth = async (
      fromDate: moment.Moment,
      toDate: moment.Moment
    ) => {
      let data: any = [];
      try {
        const response = await axios.get(
          getDateGroupAttentionsByQPsEndPoint({
            fromDate,
            toDate,
            zoneId: "-03:00",
          }),
          { timeout: 60000 }
        );
        data = response?.data.data;
        /*   console.log(data); */

        const events = data?.map((attention: any) => {
          return {
            title: `${attention.state.name} ( ${attention.count} )`,
            start: attention.fromDate,
            display: "block",
            color: color[attention.state.id],
          };
        });
        /*             console.log('Events', events); */

        setCalendarEvents(events);
        setShowGroupAttentions(true);
      } catch (error) {
        console.info("Error updating case attentions data");
        console.info(error);
      }
    };

    const handlePrevClick = () => {
      calendarRef.current?.getApi().prev();
      const firstDay = getFirstDay(calendarRef.current?.getApi().getDate());
      const lastDay = getLastDay(calendarRef.current?.getApi().getDate());

      const prevMonth = calendarDate.getMonth() - 1;
      const newDate = new Date(calendarDate);
      /*         console.log('Date ', newDate);
       */
      newDate.setMonth(prevMonth);

      setCalendarDate(newDate);
    };

    const handleNextClick = () => {
      calendarRef.current?.getApi().next();
      const firstDay = getFirstDay(calendarRef.current?.getApi().getDate());
      const lastDay = getLastDay(calendarRef.current?.getApi().getDate());

      const nextMonth = calendarDate.getMonth() + 1;
      const newDate = new Date(calendarDate);
      newDate.setMonth(nextMonth);

      setCalendarDate(newDate);
    };

    const getFirstDay = (date: any) => {
      return new Date(date.getFullYear(), date.getMonth(), 1);
    };

    const getLastDay = (date: any) => {
      return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    };

    const handleDateClick = (arg: any) => {
      /*  setShowDayList(false); */

      dailyAttentions.current = [];

      let day = moment(arg.dateStr);

      if (selectedDay !== arg.dateStr) {
        fetchAttentionsByDay(day, day)
          .then((attentions) => {
            let attention;

            Object.keys(attentions).map((key: string, i: any) => {
              attention = attentions[parseInt(key)];
              dailyAttentions.current.push(attention);
            });

            setSelectedDay(arg.dateStr);
          })
          .catch((e) => console.error(e));
      }
    };

    const buttons = {
      prevButton: {
        //icon: 'left-single-arrow',
        click: handlePrevClick,
      },
      nextButton: {
        //icon: 'right-single-arrow',
        click: handleNextClick,
      },
    };

    const buttonIcons = {
      prevButton: "chevron-left-arrow",
      nextButton: "right-single-arrow",
    };

    return (
      <>
        {requestState == "REQUEST" && (
          <>
            <p>Cargando</p>
            <CircularProgress
              style={{
                marginLeft: "5px",
              }}
              size={12}
              color="secondary"
            />
          </>
        )}
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <Typography variant="h5" color="secondary">
              Atenciones
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={handlePrevClick}
            >
              <ChevronLeftIcon />
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleNextClick}
            >
              <ChevronRightIcon />
            </Button>
          </Grid>
        </Grid>
        {showGroupAttentions && (
          <FullCalendar
            ref={calendarRef}
            locale={esLocale}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            // customButtons={buttons}
            // buttonIcons={buttonIcons}
            headerToolbar={{
              start: "title",
              center: "",
              end: "",
            }}
            dayMaxEventRows={4}
            dateClick={handleDateClick}
            initialView="dayGridMonth"
            events={calendarEvents} // Recibe los eventos que se muestran en el calendario
          />
        )}

        <DailyAttentionsList
          onAttentionChanged={getCalendarEvents}
          attentions={dailyAttentions.current}
          day={selectedDay}
          requestState={requestState}
        />
      </>
    );
});

const mapDispatchToProps = {
    updateAttentions,
    getAttentionsByQPS,
}

const mapStatesToProps = (state: any, props: any) => {
    const attentions: { [key: string]: ATTENTION } = state.attentions;
    const requestState = requestStateSelector('GET_ATTENTIONS_BY_QPS_', state);
    return {
        attentions,
        requestState,
    };
}

export default connect(mapStatesToProps, mapDispatchToProps)(AttentionsCalendar);