import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Link, NavLink, Route, Switch,
  useRouteMatch, useHistory,
} from "react-router-dom";
import Avatar from 'react-avatar';
import FlexView from 'react-flexview';

import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import TocIcon from '@material-ui/icons/Toc';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import clsx from 'clsx';
import Home from '../../modules/home/Home';
import Cases from '../../modules/mycases/Cases';
import Profile from '../../modules/profile/Profile';
import CaseDetails from '../../modules/case/CaseDetails';
import { ROUTES } from '../../routes/routes';
import { USER } from '../../shared/utils/model/user';
import SimfinixLogoText from '../../assets/Simfinix/logotext.png';
import SimfinixLogo from '../../assets/Simfinix/logo.png';
import styles from './base-layout.module.css';
import Button from '@material-ui/core/Button';
import pjson from './../../../package.json';
import { postLogout } from '../../redux/actions/userActions';

declare global {
  interface Window {
    ATL_JQ_PAGE_PROPS: any
  }
}

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      //zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    appBarClosed: {
      marginLeft:  theme.spacing(7) + 1,
      width: `calc(100% - ${theme.spacing(7) + 1}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    drawerButton: {
      width: '40px !important',
      height: '40px !important',
      backgroundColor: '#ebf1f0 !important',
      boxShadow: '0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)',
    },
    drawerButtonOpen: {
      position: 'fixed',
      marginTop: '50vh',
      marginLeft: drawerWidth - 22,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create('margin-left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    drawerButtonClosed: {
      position: 'fixed',
      marginTop: '50vh',
      marginLeft: theme.spacing(7) - 10,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create('margin-left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(9) - 22,
      },
    },
    hiddenItem: {
      display: 'none !important',
    },
    visibleItem: {
      display: 'flex !important',
    }
  }),
);

interface props{
  user: USER,
  header: string,
  postLogout: any,
}

const BaseLayout = ({user, header, postLogout}: props) => {
  const classes = useStyles();
  //const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  let { path, url } = useRouteMatch();
  let history = useHistory();

  useEffect(() => {
    let body = document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'https://adomicilio-sa.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-le44p9/b/24/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=es-ES&collectorId=cd9f1f13';
    script.async = true;
    script.defer = true;
    body.appendChild(script);

    window.ATL_JQ_PAGE_PROPS = {

      "triggerFunction": function (showCollectorDialog: any) {
        $('#feedback-button').on('click', function (e: any) {
          e.preventDefault();
          showCollectorDialog()
        });
      },
      fieldValues: {
        customfield_10032: 'aDomicilio',
        customfield_10048: 'Web Prestadores',
        fullname: user?.data?.name ? user?.data?.name : '',
        email: user?.data?.email ? user?.data?.email : '',
      }
    };
  }, []);


  const handleDrawer = () => {
    setOpen(!open);
  }

  const onCloseSession = () => {
    postLogout();
  }
  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
            [classes.appBarClosed]: !open,
          })}
          elevation={0}
        >
          <Toolbar className={classes.toolbar}>
            {/* <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawer}
              edge="start"
              // className={clsx(classes.menuButton, {
              //   [classes.hide]: open,
              // })}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton> */}

            {/* (history.length > 10) && */}
            <FlexView hAlignContent='left' style={{width: '25%'}}>
              <Button 
                className={styles.goBackButton}
                startIcon={<ChevronLeftIcon/>}
                onClick={history.goBack}
              >
                Volver
              </Button>
            </FlexView>
            <FlexView hAlignContent='center' vAlignContent='center' style={{ width: '50%' }}>
              <Typography variant="h6" noWrap className={styles.menuButton}>
                {header}
              </Typography>
            </FlexView>
            <FlexView hAlignContent='right' vAlignContent='center' style={{ width: '25%' }}>
              <Button
                className={styles.toolbarContent}
                startIcon={<ReportProblemIcon />}
                id={'feedback-button'}
              >
                Reportar error
            </Button>
            </FlexView>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={styles.drawerButtonCont}>
            <IconButton
              color='primary'
              onClick={handleDrawer}
              className={clsx(classes.drawerButton, {
                [classes.drawerButtonOpen]: open,
                [classes.drawerButtonClosed]: !open,
              })}
            >
              {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>

          {/* <div
           className={classes.toolbar}
          >
            
          </div>
          <Divider /> */}
          <List className={styles.list}>
            {/* Item del SideNav */}
            <ListItem
              key={'User'}
              className={styles.userItem}
            >
              <ListItemIcon>
                {/* <FaceIcon color='primary'/> */}
                <Avatar name={user?.data?.name} round={true} value='40%' size='30px' />
              </ListItemIcon>
              <ListItemText primary={<Typography variant='body2' className={styles.userName}>{user?.data?.name}</Typography>} />
              <IconButton onClick={openMenu} className={styles.menuButton}>
                <ExpandMoreIcon />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}
              >
                <MenuItem
                  component={Link}
                  to="/profile"
                  className={styles.menuText}
                >
                  <AccountCircleIcon />
                  <Typography variant='body2'>Mi cuenta</Typography>
                </MenuItem>
                <MenuItem onClick={onCloseSession} className={styles.menuText}>
                  <ExitToAppIcon />
                  <Typography variant='body2'>Cerrar Sesion</Typography>
                </MenuItem>
              </Menu>
            </ListItem>
            <Divider />
            <ListItem button
              component={NavLink}
              to={ROUTES.home}
              activeClassName={styles.sideBarLinkActive}
              key={'Home'}
              className={styles.sideBarLink}
            >
              <ListItemIcon><HomeIcon color='primary' /></ListItemIcon>
              <ListItemText primary={<Typography variant='body2' className={styles.link}>Inicio</Typography>} />
            </ListItem>
            <Divider />
            <ListItem button
              component={NavLink}
              to={ROUTES.cases}
              activeClassName={styles.sideBarLinkActive}
              key={'Casos'}
              //onClick={props.onClick}
              className={styles.sideBarLink}
            >
              <ListItemIcon><TocIcon color='primary' /></ListItemIcon>
              <ListItemText primary={<Typography variant='body2' className={styles.link}>Casos</Typography>} />
            </ListItem>
            {/* <Divider />
            <ListItem 
              button
              key={'LogOut'}
              onClick={onCloseSession}
            >
              <ListItemIcon><ExitToAppIcon color='primary' /></ListItemIcon>
              <ListItemText primary={'Cerrar Sesion'} />
            </ListItem> */}
          </List>
          <FlexView
            column vAlignContent='bottom' hAlignContent='center'
            className={clsx(styles.bottomCont, {
              [classes.hiddenItem]: !open,
              [classes.visibleItem]: open,
            })}
          >
            <FlexView vAlignContent='center' hAlignContent='center'>
              <span className={styles.text}>Powered By</span>
              <img src={SimfinixLogoText} className={styles.logoText} />
            </FlexView>
            <FlexView vAlignContent='center' hAlignContent='center' className={styles.version}>
              <span className={styles.text}>v.{pjson.version}</span>
            </FlexView>
          </FlexView>
          <FlexView
            column vAlignContent='bottom' hAlignContent='center'
            className={clsx(styles.bottomCont, {
              [classes.hiddenItem]: open,
              [classes.visibleItem]: !open,
            })}
          >
            <FlexView vAlignContent='center' hAlignContent='center'>
              <img src={SimfinixLogo} className={styles.logo} />
            </FlexView>
          </FlexView>
        </Drawer>
        {/* Main Content */}
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            <Route exact path={ROUTES.home} component={Home} />
            <Route exact path={ROUTES.cases} component={Cases} />
            <Route exact path={ROUTES.profile} component={Profile} />
            <Route exact path={ROUTES.case} component={CaseDetails} />
            <Route exact path='/' component={Home} />
          </Switch>
        </main>
      </div>
    </Router>
  );
}

const mapDispatchToProps = {
  postLogout,
}

const mapStatesToProps = (state: any, props: any) => {
  const user: USER = state.user;
  const header: string = state.header;

  return {
    user,
    header,
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(BaseLayout);