import axios from "axios";
import { getProvisionsCaseEndPoint } from "../../shared/utils/apis";
import { PROVISION } from "../../shared/utils/model/provision";
import { SET_PROVISIONS, UPDATE_PROVISIONS } from "../action_types";
import { getProvisionsFailure } from "./failureActions";
import { getProvisionsRequest } from "./requestActions";
import { getProvisionsSuccess } from "./successActions";

export const updateProvisions = (caseId: number) => async (dispatch: any) => {
    dispatch(getProvisionsRequest());
    let provisions: PROVISION[] = [];
    try {
       const response = await axios.get(
          getProvisionsCaseEndPoint(caseId),
          {
             timeout: 5000,
          },
       );
       provisions = response?.data?.data;
       dispatch({
            type: UPDATE_PROVISIONS,
            payload: provisions,
       });
       dispatch(getProvisionsSuccess());
    } catch (error) {
       console.info('Error updating provisions data. ',error.message);
       dispatch(getProvisionsFailure());
    }
}