import {
  GET_CASES_SUCCESS,
  GET_CASE_BY_ID_SUCCESS,
  UPDATE_ATTENTIONS_SUCCESS,
  POST_ATTENTION_SUCCESS,
  GET_PROVISIONS_SUCCESS,
  GET_TENANT_SUCCESS,
  POST_LOGIN_SUCCESS,
  GET_USER_DATA_SUCCESS,
  POST_LOGOUT_SUCCESS,
  POST_RESTORE_PASSWORD_SUCCESS,
  PATCH_ATTENTION_SUCCESS,
  POST_EVOLUTION_FORM_SUCCESS,
  POST_GFORM_EVOLUTION_SUCCESS,
  POST_EVOLUTION_SUCCESS,
  GET_ATTENTIONS_BY_QPS_SUCCESS,
  UPDATE_EVOLUTIONS_SUCCESS,
  GET_EVOLUTION_PHOTO_SUCCESS,
  GET_CATEGORIES_SUCCESS,
} from "../action_types";

export const getCasesSuccess = () => ({
  type: GET_CASES_SUCCESS,
});

export const getCaseByIdSuccess = () => ({
  type: GET_CASE_BY_ID_SUCCESS,
});

export const updateAttentionSuccess = () => ({
  type: UPDATE_ATTENTIONS_SUCCESS,
});

export const postAttentionSuccess = () => ({
  type: POST_ATTENTION_SUCCESS,
});

export const getProvisionsSuccess = () => ({
  type: GET_PROVISIONS_SUCCESS,
});

export const getTenantSuccess = () => ({
  type: GET_TENANT_SUCCESS,
});

export const postLoginSuccess = () => ({
  type: POST_LOGIN_SUCCESS,
});

export const getUserDataSuccess = () => ({
  type: GET_USER_DATA_SUCCESS,
});

export const postLogoutSuccess = () => ({
  type: POST_LOGOUT_SUCCESS,
});

export const postRestorePasswordSuccess = () => ({
  type: POST_RESTORE_PASSWORD_SUCCESS,
});

export const patchAttentionSuccess = () => ({
  type: PATCH_ATTENTION_SUCCESS,
});

export const postEvolutionFormSuccess = () => ({
  type: POST_EVOLUTION_FORM_SUCCESS,
});

export const postGFormEvolutionSuccess = () => ({
  type: POST_GFORM_EVOLUTION_SUCCESS,
});

export const postEvolutionSuccess = () => ({
  type: POST_EVOLUTION_SUCCESS,
});

export const getAttentionsByQPSSuccess = () => ({
  type: GET_ATTENTIONS_BY_QPS_SUCCESS,
});

export const updateEvolutionsSuccess = () => ({
  type: UPDATE_EVOLUTIONS_SUCCESS,
});

export const getEvolutionPhotoSuccess = () => ({
  type: GET_EVOLUTION_PHOTO_SUCCESS,
});

export const getCategoriesSuccess = () => ({
  type: GET_CATEGORIES_SUCCESS,
});
