import moment from "moment";

export const dayOfWeekMapper: any = {
  0: "Domingo",
  1: "Lunes",
  2: "Martes",
  3: "Miércoles",
  4: "Jueves",
  5: "Viernes",
  6: "Sábado",
};

export const monthMapper: any = {
  0: "Enero",
  1: "Febrero",
  2: "Marzo",
  3: "Abril",
  4: "Mayo",
  5: "Junio",
  6: "Julio",
  7: "Agosto",
  8: "Septiembre",
  9: "Octubre",
  10: "Noviembre",
  11: "Diciembre",
};

export const formatDate = (_date: string) => {
  const _moment = moment(_date);
  const day = dayOfWeekMapper[_moment.day()];
  const month = monthMapper[_moment.month()];
  const date = _moment.date();
  return `${day}, ${date} de ${month}`;
};
