import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import BaseLayout from '../core/base-layout/base-layout';
import Login from '../modules/login/Login';
import NotFound from '../modules/NotFound';
import { ROUTES } from './routes';
import { setUserData, setSession, } from '../redux/actions/userActions';
import CaseDetails from '../modules/case/CaseDetails';

const App = (user) => {
    const isLoggedIn = (user) => {
        if (!!(user?.session?.accessToken)) {
            return true
        }
        else {
            return false
        }
    }

    const PrivateRoute = ({ component: Component, ...rest }) => (
        <Route {...rest} render={(props) => (
            isLoggedIn(user)
                ? <Component {...props} />
                : <Redirect to='/login' />
        )} />
    )

    const LoginRoute = ({ component: Component, ...rest }) => (
        <Route {...rest} render={(props) => (
            isLoggedIn(user)
                ? <Redirect to='/home' />
                : <Component {...props} />
        )} />
    )

    return (
        <BrowserRouter>
            <Switch>
                <LoginRoute exact path={ROUTES.login} component={Login} />
                {/* <Route exact path={ROUTES.login} component={Login} />  default screen */}
                <PrivateRoute exact path={ROUTES.home} component={BaseLayout} />
                <PrivateRoute exact path={ROUTES.cases} component={BaseLayout} />
                <PrivateRoute exact path={ROUTES.profile} component={BaseLayout} />
                <PrivateRoute exact path='/' component={BaseLayout} />
                <PrivateRoute exact path={ROUTES.case} component={BaseLayout} />
                {/* <Route exact path={ROUTES.cases} component={Cases} />
                <Route exact path={ROUTES.case} component={CaseDetails} /> */}
                <Route component={NotFound} />
            </Switch>
        </BrowserRouter>
    )
}

const mapStateToProps = (state, props) => {
    const user = state.user;
    return user;
}

export default connect(mapStateToProps, { setSession, setUserData })(App);
