import { ACTION } from "../../shared/utils/model/action";
import { ATTENTION }  from "../../shared/utils/model/attention";
import {
  SET_ATTENTIONS,
  UPDATE_ATTENTIONS,
  UPDATE_ATTENTION,
} from "../action_types";
const _ = require("lodash");

const initialState: Partial<ATTENTION> = {};

function attention(
  state: Partial<ATTENTION> = initialState,
  action: ACTION<ATTENTION>
): Partial<ATTENTION> {
  switch (action.type) {
    case SET_ATTENTIONS:
      return { ...state, ...action.payload };
    case UPDATE_ATTENTIONS: {
      const attentions = _.mapKeys(action.payload, "id");
      return { ...attentions };
    }
    case UPDATE_ATTENTION: {
      const attentions: any = { ...state };
      const { id } = action.payload;
      attentions[id] = action.payload;
      return attentions;
    }
    default:
      return state;
  }
}

export default attention;