import { ACTION } from "../../shared/utils/model/action";
import { CASES } from "../../shared/utils/model/cases";
import { SET_ACTUAL_CASE }  from "../action_types";

const initialState: number = 0;

function cases(state: number = initialState , action: ACTION<number>): number {
    switch (action.type) {
        case SET_ACTUAL_CASE:
            return action.payload
        default:
            return state
    }
}

export default cases;