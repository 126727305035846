import { ACTION } from "../../shared/utils/model/action";
import { SET_HEADER } from "../action_types";

const initialState: string = '';

function header(state: string = initialState, action: ACTION<string>): string {
    switch (action.type) {
        case SET_HEADER: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export default header;