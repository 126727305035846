import { createStore, compose, applyMiddleware } from 'redux';
import rootReducer, { initialState } from './reducers/rootReducer';
import reduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [
    reduxThunk,
    composeEnhancers(),
];

const store =  createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(reduxThunk)),
);

export default store;