import { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FileLoader from "../FileLoader/FileLoader";
import moment from "moment";
import { Checkbox, FormControlLabel, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import GFormDialog from "./GFormDialog";
import EvolutionDateDialog from "./EvolutionDateDialog";
import EvolutionCategoriesDialog from "./EvolutionCategoriesDialog";
import { useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {
  updateAttentions,
  patchAttention,
} from "../../../../redux/actions/attentionActions";
import {
  postEvolution,
  postMedia,
} from "../../../../redux/actions/evolutionActions";
import { requestStateSelector } from "../../../../redux/reducers/requestStateReducer";
import {
  postGFormEvolutionClose,
  postEvolutionClose,
  patchAttentionClose,
} from "../../../../redux/actions/closeActions";
import { CATEGORY } from "../../../../shared/utils/model/category";
import ATTENTION_STATE from "../../../../shared/utils/enums/attentionState";
import { ATTENTION } from "../../../../shared/utils/model/attention";

interface props {
  attention: ATTENTION;
  open: boolean;
  showDescription: boolean;
  requestStateGFormEvolution: string;
  requestStateEvolution: string;
  requestStateAttention: string;
  handleClose: any;
  formData: any;
  postEvolution: any;
  patchAttention: any;
  updateAttentions: any;
  onAttentionChanged: any;
}

const initialSnackbar = {
  text: "",
  severity: "",
};

const EvolveAttentionDialog = ({
  attention,
  open,
  showDescription,
  requestStateEvolution,
  requestStateAttention,
  handleClose,
  formData,
  postEvolution,
  patchAttention,
  updateAttentions,
  onAttentionChanged,
}: props) => {
  const dispatch = useDispatch();
  const [fromDateString, setFromDateString] = useState(
    moment().format("YYYY-MM-DDTHH:mm")
  );
  const [toDateString, setToDateString] = useState(
    moment().add(1, "hours").format("YYYY-MM-DDTHH:mm")
  );
  const [fromDate, setFromDate] = useState<Date>(new Date());
  const [toDate, setToDate] = useState<Date>(
    new Date(moment().add(1, "hours").format())
  );
  const [quantity, setQuantity] = useState(1);
  const [description, setDescription] = useState<string | null>(null);
  const [openGForm, setOpenGForm] = useState(false);
  const [criticEvolution, setCriticEvolution] = useState(false);
  const [validDate, setValidDate] = useState(true);
  const [validQuantity, setValidQuantity] = useState(true);
  const [validDescription, setValidDescription] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [quantityError, setQuantityError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [requestStateGFormFlag, setRequestStateGFormFlag] = useState("");
  const [media, setMedia] = useState<any>("");
  const { id: caseId }: any = useParams();

  const [evolutionDialogOpen, setEvolutionDialogOpen] = useState(false);
  const [dateDialogOpen, setDateDialogOpen] = useState(false);
  const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [defaultText, setDefaultText] = useState<string | null>("");
  const [useScheduledDate, setUseScheduledDate] = useState(false);
  const [snackbar, setSnackbar] = useState<any>(initialSnackbar);
  const [formInfo, setFormInfo] = useState( null );

  useEffect(() => {
    if (open) initializeDialog();
  }, [open]);

  useEffect(() => {
    showSnackbar();
  }, [requestStateAttention, requestStateEvolution, requestStateGFormFlag]);

  const initializeDialog = () => {
    setFromDateString(moment().format("YYYY-MM-DDTHH:mm"));
    setToDateString(moment().add(1, "hours").format("YYYY-MM-DDTHH:mm"));
    setFromDate(new Date());
    setToDate(new Date(moment().add(1, "hours").format()));
    setQuantity(1);
    setDescription(null);
    setOpenGForm(false);
    setCriticEvolution(false);
    setValidDate(true);
    setValidQuantity(true);
    setValidDescription(false);
    setDateError(false);
    setQuantityError(false);
    setDescriptionError(false);
    setRequestStateGFormFlag("");
    setMedia("");
    setEvolutionDialogOpen(false);
    setDateDialogOpen(false);
    setCategoryDialogOpen(true);
    setSelectedCategory(null);
    setDefaultText("");
    setUseScheduledDate(false);
    setSnackbar(initialSnackbar);
  };

  const validateDates = (from?: Date, to?: Date) => {
    const dateFrom = moment(from);
    const dateLimit = moment().add(1, "days");

    if (from != null && dateFrom.isBefore(dateLimit)) {
      setValidDate(true);
      setDateError(false);
    } else {
      setValidDate(false);
      setDateError(true);
    }
  };

  const validateQuantity = (quantity: number) => {
    if (!!quantity && quantity > 0) {
      const newToString = moment(fromDate)
        .add(quantity, "hours")
        .format("YYYY-MM-DDTHH:mm");
      const newTo = new Date(newToString);

      setValidQuantity(true);
      setToDate(newTo);
      setToDateString(newToString);
      setQuantityError(false);
    } else {
      setValidQuantity(false);
      setQuantityError(true);
    }
  };

  const validateDescription = (desc: string) => {
    if (desc.length < 10) {
      setDescriptionError(true);
      setValidDescription(false);
    } else {
      setDescriptionError(false);
      setValidDescription(true);
    }
  };

  const fromDateHandler = (event: any) => {
    const newFrom = new Date(event.target.value);
    const newToString = moment(newFrom)
      .add(quantity, "hours")
      .format("YYYY-MM-DDTHH:mm");
    const newTo = new Date(newToString);

    validateDates(newFrom, newTo);
    setFromDate(newFrom);
    setToDate(newTo);
    setToDateString(newToString);
  };

  const toDateHandler = (event: any) => {
    const newTo = new Date(event.target.value);
    setToDate(new Date(event.target.value));
    validateDates(fromDate, newTo);
  };

  const quantityHandler = (event: any) => {
    const quantity: number = event.target.value;
    validateQuantity(quantity);
    setQuantity(event.target.value);
  };

  const descriptionHandler = (event: any) => {
    validateDescription(event.target.value);
    setDescription(event.target.value);
  };

  const attentionHandler = async () => {
    handleCloseDialog();
    let evolutionType = 1;
    const attentionId = attention.id;

    try {
      const categoryId = selectedCategory;
      const photoDataUrl = media ?? null;

      if (showDescription) {
        if (criticEvolution) evolutionType = 4;

        await postEvolution(
          attentionId,
          categoryId,
          toDate,
          description,
          evolutionType,
          photoDataUrl
        );
      } else {
        const safeFormInfo = formInfo ?? { description: '', evolutionType: 1, formType: '' };
        await postEvolution(
          attentionId,
          categoryId,
          toDate,
          safeFormInfo.description,
          safeFormInfo.evolutionType,
          photoDataUrl,
          safeFormInfo.formType
        );
      }

      if (attention.state.id === 1) {
        await patchAttention(attentionId, fromDate, toDate, quantity);

        // If the attention is accessed from the case screen, call updateAttentions with the current caseId,
        // otherwise, use the callback function onAttentionChanged provided by the parent component DailyAttentionRow
        if (!!caseId) {
          updateAttentions(caseId);
        } else {
          onAttentionChanged({
            ...attention,
            state: { id: ATTENTION_STATE.DONE, name: "Realizada" },
          });
        }
      }
    } catch (error) {
      console.error(error);
      console.error("No se pudo modificar la atención.");
      if (!showDescription) {
        setRequestStateGFormFlag("FAILURE");
      }
    }
  };

  const handlePostMedia = () => {
    const evolPhoto = {
      attentionId: attention.id,
      mediaUUID: formData.mediaUUID,
      url: media,
    };
    dispatch(postMedia(evolPhoto));
  };

  const openGFormDialog = () => {
    setDateDialogOpen(false);
    setEvolutionDialogOpen(false);
    setOpenGForm(true);
  };

  const showSnackbar = () => {
    if (requestStateAttention === "SUCCESS") {
      setSnackbar({
        text: "Atención realizada con éxito",
        severity: "success",
      });
    } else if (requestStateAttention === "FAILURE") {
      setSnackbar({
        text: "Error al realizar la atención",
        severity: "error",
      });
    } else if (
      requestStateEvolution === "SUCCESS" ||
      requestStateGFormFlag === "SUCCESS"
    ) {
      setSnackbar({
        text: "Evolución realizada con éxito",
        severity: "success",
      });
    } else if (
      requestStateEvolution === "FAILURE" ||
      requestStateGFormFlag === "FAILURE"
    ) {
      setSnackbar({
        text: "Error al realizar la evolución",
        severity: "error",
      });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar(initialSnackbar);
    setRequestStateGFormFlag("");
    dispatch(postGFormEvolutionClose());
    dispatch(postEvolutionClose());
    dispatch(patchAttentionClose());
  };

  const handleSwitchChange = () => {
    setCriticEvolution(!criticEvolution);
  };

  const handleCloseDialog = () => {
    setValidDate(false);
    setDateError(false);
    setValidDescription(false);
    setDescriptionError(false);
    setValidQuantity(false);
    setQuantityError(false);
    setSelectedCategory(null);
    setDefaultText(null);
    setCategoryDialogOpen(false);
    setDateDialogOpen(false);
    setEvolutionDialogOpen(false);
    handleClose();
  };

  const handleSelectCategory = (category: CATEGORY) => {
    setSelectedCategory(category.id);
    if (!!category.defaultText) {
      validateDescription(category.defaultText ?? "");
      setDescription(category.defaultText);
      setDefaultText(category.defaultText);
    }
    setCategoryDialogOpen(false);
    setDateDialogOpen(true);
  };

  const handleSelectDateOption = (useScheduled: boolean) => {
    setUseScheduledDate(useScheduled);

    setDateDialogOpen(false);

    if (useScheduled) {
      setFromDate(moment(attention.fromDate).toDate());
      attention.toDate? setToDate(moment(attention.toDate).toDate()) : setToDate(moment(attention.fromDate).toDate()) ;
      setValidDate(true);

      if (showDescription) {
        setEvolutionDialogOpen(true);
      } else {
        openGFormDialog();
      }
    } else {
      setEvolutionDialogOpen(true);
    }
  };

  const setLocalDataForm = async ( form:any, attachFile:any ) => {
    if (attention.practice.restriction.requiredToDate) {
      setMedia(attachFile);
      setFormInfo(form);
      setEvolutionDialogOpen(true);
    } else {
      // Practice "Con Formulario" without "Sin Horas" - workaround
      setFormInfo(form);      
      await attentionHandler();
      if (!!media) {
        await postMedia( attachFile );
      }
    }
  };

  return (
    <>
      <Dialog
        open={evolutionDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h5" color="secondary">
            Detalles de la atención
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Complete los cuadros que aparecen a continuación.
          </DialogContentText>
          <Grid container spacing={1}>
            {attention.practice.restriction.requiredToDate && (
              <>
                <Grid item md={12}>
                  <TextField
                    error={dateError}
                    onChange={fromDateHandler}
                    defaultValue={fromDateString}
                    autoFocus
                    margin="dense"
                    id="date-start"
                    label="Fecha de inicio"
                    type="datetime-local"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    error={dateError}
                    onChange={toDateHandler}
                    value={toDateString}
                    margin="dense"
                    id="date-end"
                    label="Fecha de finalización"
                    type="datetime-local"
                    disabled
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    error={quantityError}
                    onChange={quantityHandler}
                    defaultValue={1}
                    margin="dense"
                    id="date-end"
                    label="Cantidad de horas"
                    type="number"
                  />
                </Grid>
              </>
            )}
            {showDescription && (
              <>
                <Grid item md={12}>
                  <TextField
                    error={descriptionError}
                    defaultValue={defaultText}
                    onChange={descriptionHandler}
                    margin="dense"
                    id="description"
                    label="Estado del paciente"
                    type="text"
                    multiline
                    fullWidth
                  />
                </Grid>
                <Grid item md={12}>
                  <FormControlLabel
                    style={{ color: "grey" }}
                    control={
                      <Checkbox
                        checked={criticEvolution}
                        onChange={handleSwitchChange}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label="Evolución Crítica"
                  />
                </Grid>
                <Grid item md={12}>
                  <FileLoader
                    file={media}
                    onFileLoaded={setMedia}
                    onRemoveFile={() => setMedia("")}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancelar
          </Button>
          <Button
            onClick={attentionHandler}
            variant="contained"
            color="primary"
            disabled={
              !validDate ||
              !validQuantity ||
              (!validDescription && showDescription)
            }
            style={{ color: "white" }}
          >
            Evolucionar
          </Button>
        </DialogActions>
      </Dialog>

      <GFormDialog
        media={media}
        setMedia={setMedia}
        formData={formData}
        open={openGForm}
        postMedia={handlePostMedia}
        handleClose={() => setOpenGForm(false)}
        submitEvolution={setLocalDataForm}
      />

      {categoryDialogOpen && (
        <EvolutionCategoriesDialog
          attention={attention}
          open={categoryDialogOpen}
          onDismiss={handleCloseDialog}
          onSelectCategory={handleSelectCategory}
        />
      )}

      <EvolutionDateDialog
        open={dateDialogOpen}
        onDismiss={handleCloseDialog}
        onSelectOption={handleSelectDateOption}
      />

      <Snackbar
        open={!!snackbar.text}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.text}
        </Alert>
      </Snackbar>
    </>
  );
};

const mapDispatchToProps = {
  updateAttentions,
  postEvolution,
  patchAttention,
  postGFormEvolutionClose,
  postEvolutionClose,
  patchAttentionClose,
};

const mapStatesToProps = (state: any, props: any) => {
  const requestStateGFormEvolution = requestStateSelector(
    "POST_GFORM_EVOLUTION_",
    state
  );
  const requestStateEvolution = requestStateSelector("POST_EVOLUTION_", state);
  const requestStateAttention = requestStateSelector("PATCH_ATTENTION_", state);
  return {
    requestStateGFormEvolution,
    requestStateEvolution,
    requestStateAttention,
  };
};

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(EvolveAttentionDialog);
