import React from "react";
import HighlightOff from "@material-ui/icons/HighlightOff";
import { IconButton } from "@material-ui/core";
import styles from "./FileLoader.module.css";

interface props {
  file: string;
  onFileLoaded: (value: any) => void;
  onRemoveFile: () => void;
}

const FileLoader = ({ file, onFileLoaded, onRemoveFile }: props) => {
  const handleFileSelection = (event: { target: HTMLInputElement }) => {
    if (
      !!event &&
      !!event.target &&
      !!event.target.files &&
      !!event.target.files.length
    ) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = (result) => onFileLoaded(result?.target?.result);
    }
  };

  return (
    <>
      {!file ? (
        <div className={styles.centered_content_row}>
          <div className={styles.dropbox}>
            <input
              type="file"
              accept="image/png, image/jpeg, application/pdf"
              onChange={handleFileSelection}
              className={styles.file_input}
            />
            <p>
              Arrastre el archivo o haga click <u>aquí</u>
            </p>
          </div>
        </div>
      ) : (
        <div className={styles.centered_content_row}>
          <div className={styles.preview_container}>
            <IconButton
              size="small"
              className={styles.remove_btn}
              onClick={onRemoveFile}
            >
              <HighlightOff color="error" />
            </IconButton>
            {file.includes("application/pdf") ? (
              <div className={styles.pdf_icon}>PDF</div>
            ) : (
              <img className={styles.img_preview} src={file} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

FileLoader.defaultProps = {
  onFileLoaded: () => {},
};

export default FileLoader;
