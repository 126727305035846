import { ACTION } from "../../shared/utils/model/action";
const _ = require("lodash"); 

interface requestState {
    //attention service
    GET_ATTENTION_: string,
    POST_ATTENTION_: string,
    PATCH_ATTENTION_: string,
    UPDATE_ATTENTIONS_: string,
    GET_ATTENTIONS_BY_QPS: string,

    //authentication service
    GET_TENANTS_: string,
    POST_LOGIN_: string,
    POST_LOGOUT_: string,
    GET_USER_DATA_: string,
    GET_TENANT_: string,
    POST_RESTORE_PASSWORD_: string,
    
    //cases service
    GET_CASES_: string,
    GET_ACTUAL_CASE_: string,
    
    //evolution service
    POST_EVOLUTION_FORM_: string,
    POST_GFORM_EVOLUTION_: string,
    POST_EVOLUTION_: string,

    //provision service
    GET_PROVISIONS_: string,
}

const initialState: requestState = {
    GET_ATTENTION_: '',
    POST_ATTENTION_: '',
    PATCH_ATTENTION_: '',
    UPDATE_ATTENTIONS_: '',
    GET_ATTENTIONS_BY_QPS: '',
    GET_TENANTS_: '',
    POST_LOGIN_: '',
    POST_LOGOUT_: '',
    GET_USER_DATA_: '',
    GET_TENANT_: '',
    POST_RESTORE_PASSWORD_: '',
    GET_CASES_: '',
    GET_ACTUAL_CASE_: '',
    POST_EVOLUTION_FORM_: '',
    POST_GFORM_EVOLUTION_: '',
    POST_EVOLUTION_: '',
    GET_PROVISIONS_: '',
};

function requestState(state:requestState = initialState , action: ACTION<any>) {
    const { type } = action;
    const RSF = /(.*_)*(REQUEST|SUCCESS|FAILURE)/.exec(type);    
    if(RSF){
        const [, requestName, requestState] = RSF;
        return{
            ...state,
            [requestName]: requestState,       
        }
    }
    const closeSnackbar = /(.*_)*(CLOSE)/.exec(type);    
    if(closeSnackbar){
        const [, requestName, requestState] = closeSnackbar;
        return{
            ...state,
            [requestName]: '',       
        }
    }
    else{
        return state;
    }
};

export default requestState;

export const requestStateSelector = (action: any, state: any) => {
    return state.requestState[action];
  };
