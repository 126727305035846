enum ATTENTION_STATE {
    REGISTERED = 1,
    IN_PROGRESS = 2,
    DONE = 3,
    NOT_DONE = 4,
    PAYABLE = 5,
    DEBITED = 6,
    IN_PROCESS = 7,
    INFORMED = 8,
    NOT_INFORMED = 9,
 }
 
 export default ATTENTION_STATE;
 