import { createMuiTheme } from '@material-ui/core/styles';
import {blue, green, yellow, red, orange, lightBlue, lightGreen} from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#8cb1a2',
    },
    secondary:{
      main: '#e5750a',
    },
    error: red,
    warning: yellow,
    info: lightBlue,
    success: green,
  },
  typography: {
    fontFamily: [
      '"Source Sans Pro", Sanchez', 
    ].join(','),
  },
});

export default theme;