import React from "react";
import { parseUri } from "../../../../shared/utils/media";
import styles from "./Media.module.css";
import VisibilityIcon from "@material-ui/icons/Visibility";

interface props {
  id: number;
  mediaData: string;
}

const Media = ({ id, mediaData }: props) => {
  const { type, extension } = parseUri(mediaData);

  let media;

  switch (type) {
    case "image":
      media = (
        <div className={styles.mediaContainer}>
          <a download={`${id}.${extension}`} href={mediaData}>
            <img src={mediaData} className={styles.imgPreview} />
          </a>
        </div>
      );
      break;
    default:
      media = (
        <div className={styles.mediaContainer}>
          <a
            className={styles.pdfLinkText}
            download={`${id}.${extension}`}
            href={mediaData}
          >
            <div className={styles.pdfLink}>
              {extension}
              <VisibilityIcon />
            </div>
          </a>
        </div>
      );
  }

  return <>{media}</>;
};

export default Media;
