import { useEffect } from "react";
import { connect } from "react-redux";
import { Alert } from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import { CATEGORY } from "../../../../shared/utils/model/category";
import { requestStateSelector } from "../../../../redux/reducers/requestStateReducer";
import { getCategories } from "../../../../redux/actions/evolutionActions";
import { getCategoriesClose } from "../../../../redux/actions/closeActions";

interface props {
  open: boolean;
  onDismiss: () => void;
  onSelectCategory: (value: CATEGORY) => void;
  getCategories: (attentionId: string) => void;
  getCategoriesClose: () => void;
  attention: any;
  categories: { byId: { [key: string]: CATEGORY }; allIds: string[] };
  requestState: string;
}

const EvolutionCategoriesDialog = ({
  attention,
  open,
  onDismiss,
  onSelectCategory,
  getCategories,
  getCategoriesClose,
  categories,
  requestState,
}: props) => {
  const handleSnackbarClose = () => {
    getCategoriesClose();
    onDismiss();
  };

  useEffect(() => {
    getCategories(attention.id);
  }, [attention]);

  return (
    <>
      <Dialog
        open={open && requestState === "SUCCESS"}
        onClose={onDismiss}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h5" color="secondary">
            Seleccione un tipo de evolución
          </Typography>
        </DialogTitle>
        <DialogContent>
          {categories.allIds.map((categoryId) => (
            <Button
              onClick={() => onSelectCategory(categories.byId[categoryId])}
              variant="contained"
              color={
                categories.byId[categoryId].category === "MEDICA"
                  ? "secondary"
                  : "primary"
              }
              style={{ color: "white", width: "100%", marginBottom: 16 }}
            >
              {categories.byId[categoryId].category}
            </Button>
          ))}
        </DialogContent>
      </Dialog>
      <Snackbar
        open={requestState == "FAILURE"}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          Error al obtener los tipos de evoluciones.
        </Alert>
      </Snackbar>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const categories: { byId: { [key: string]: CATEGORY }; allIds: string[] } =
    state.categories;
  const requestState = requestStateSelector("GET_CATEGORIES_", state);
  return {
    categories,
    requestState,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  getCategories: (attentionId: string) => dispatch(getCategories(attentionId)),
  getCategoriesClose: () => dispatch(getCategoriesClose()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EvolutionCategoriesDialog);
