import { ACTION } from "../../shared/utils/model/action";
import { CATEGORY } from "../../shared/utils/model/category";
import { SET_CATEGORIES } from "../action_types";
const _ = require("lodash");

interface categoriesState {
  byId: { [id: string]: CATEGORY };
  allIds: string[];
}

const initialState: categoriesState = {
  byId: {},
  allIds: [],
};

function categories(
  state: categoriesState = initialState,
  action: ACTION<CATEGORY[]>
): Partial<categoriesState> {
  switch (action.type) {
    case SET_CATEGORIES: {
      const filteredCategories = action.payload.filter(
        ({ category, createProvider, active }) =>
          !!category && !!createProvider && !!active
      );
      const byId = _.mapKeys(filteredCategories, "id");
      const allIds = Object.keys(byId);
      const categories = { byId, allIds };
      return { ...categories };
    }
    default:
      return state;
  }
}

export default categories;
