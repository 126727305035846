import { useEffect, useRef, useState } from "react";
import { JSONSchemaBridge } from "uniforms-bridge-json-schema";

import { WizardJsonFormProps } from ".";

import createValidator from "./validation";
import mapFormData from "./mapper";
import DateField from "./components/DateField";
import DateTimeField from "./components/DateTimeField";
import { AutoField } from "uniforms-material";

export default function useWizardJsonForm({
  initialStep,
  onSubmit,
  form,
}: Partial<WizardJsonFormProps>) {
  let [mappedForm, setMappedForm] = useState<any>(mapFormData(form));

  let [step, setStep] = useState<number>(initialStep ?? 0);

  let [isLast, setIsLast] = useState<boolean>(false);

  let [totalSteps, setTotalSteps] = useState<any>(null);
  let [schema, setSchema] = useState<any>(null);
  let [values, setValues] = useState<any>({});
  let [error, setError] = useState<any>(null);
  let [type, setType] = useState<any>(null);
  let [steps, setSteps] = useState<Array<any>>([
    { actualValue: 0, previousValue: null },
  ]);

  let previousStep = useRef<any>(null);

  let formRef = useRef<any>(null);

  useEffect(() => {
    if (!!form) {
      setMappedForm(mapFormData(form));
    }
  }, [form]);

  useEffect(() => {
    setStep(initialStep ?? 0);
  }, [initialStep]);

  useEffect(() => {
    if (!!form) {
      setTotalSteps(form?.formSections?.length - 1);
    }
  }, [form]);

  useEffect(() => {
    if (!!mappedForm) {
      try {
        if (mappedForm?.formSections?.[step]) {
          let formSection = mappedForm?.formSections?.[step];

          if (!!formSection?.formData) {
            setSchema(formSection?.formData);
            setType(formSection?.formData?.title);
            setIsLast(formSection?.final === 1);
          }
        }
      } catch (err) {
        setError(err);
      }
    }
  }, [mappedForm, step]);

  let handleChangeModel = (model: any) => {
    let reducedModel = Object.keys(schema.properties).reduce(
      (accum: any, key: any) => {
        let property = schema?.properties?.[key];
        let value = model?.[key];

        if (!!value) {
          accum[key] = {
            title: property?.uniforms?.title,
            values: property?.allowedValues,
            goTo: property?.uniforms?.goTo,
            value,
          };
        }

        return accum;
      },
      {}
    );

    setValues({
      ...values,
      ...reducedModel,
    });
  };

  let handleGoBack = () => {
    if (steps.length !== null) {
      let newStep = step;
      previousStep.current = steps.find(
        (s) => s.actualValue === newStep
      ).previousValue;
      setStep(previousStep.current);
    }

    /* if (!isNaN(previousStep.current) && previousStep.current !== step) {
      setStep(previousStep.current);
    } else {
      setStep(step - 1);
    } */
  };

  let handleGoNext = async () => {
    let newPreviousStep = step;
    let newStep: any;

    try {
      let error = await formRef?.current?.validate?.();

      if (!!error && Object.keys(error).length > 0) {
        throw "hasValidationErrors";
      }

      formRef?.current?.submit?.();

      let key: any = Object.keys(schema.properties).find(
        (key) => !!schema?.properties?.[key]?.uniforms?.goTo
      );

      previousStep.current = step;

      if (!!key) {
        let info = values?.[key];

        let index = info?.values?.findIndex?.((v: any) => v === info?.value);

        newStep = info?.goTo?.[index] - 1;
      } else {
        newStep = step + 1;
      }
    } catch (err) {
      newStep = step;
    }

    setStep(newStep);

    //Aquí se actualiza la lista de combinaciones step/previousStep

    let valuesSteps = steps;
    let duplicateSteps = valuesSteps.filter((s) => {
      return s.actualValue === newStep;
    });

    if (duplicateSteps.length === 0) {
      valuesSteps.push({
        actualValue: newStep,
        previousValue: newPreviousStep,
      });
    } else {
      valuesSteps[valuesSteps.indexOf(duplicateSteps[0])] = {
        actualValue: newStep,
        previousValue: newPreviousStep,
      };
    }
    setSteps(valuesSteps);
  };

  let handleSubmit = async () => {
    try {
      let error = await formRef?.current?.validate?.();

      if (!!error && Object.keys(error).length > 0) {
        throw "hasValidationErrors";
      }

      if (mappedForm?.formSections?.[step]?.final) {
        if (typeof onSubmit === "function") {
          await onSubmit?.(type, values);
        }
      }
    } catch (err) {}
  };

  let customComponentDetector = (props: any, uniforms: any) => {
    if (props?.field?.format === "date") {
      return DateField;
    }

    if (props?.field?.format === "date-time") {
      return DateTimeField;
    }

    return AutoField.defaultComponentDetector(props, uniforms);
  };

  let jsonSchema: any = !!schema
    ? new JSONSchemaBridge(schema, createValidator(schema))
    : null;

  let shouldRenderBackButton = step > 0;
  let shouldRenderSubmitButton = isLast;
  let shouldRenderNextButton = step < totalSteps && !isLast;

  return {
    step,
    error,
    schema,
    values,
    formRef,
    totalSteps,
    jsonSchema,
    shouldRenderBackButton,
    shouldRenderNextButton,
    shouldRenderSubmitButton,
    handleSubmit,
    handleGoBack,
    handleGoNext,
    handleChangeModel,
    customComponentDetector,
  };
}
