import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ROUTES } from '../../routes/routes';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchBar from "material-ui-search-bar";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { CASES } from '../../shared/utils/model/cases';
// import { getCases } from '../../shared/services/casesService';
import { updateCases } from '../../redux/actions/caseActions';
import { setHeader } from '../../redux/actions/headerActions';
import styles from './Cases.module.css';
import WaitingAnimation from '../../shared/modules/WaitingAnimation/WaitingAnimation';
import { requestStateSelector } from '../../redux/reducers/requestStateReducer';
import { getCasesClose } from '../../redux/actions/closeActions';

const Headers = [
  'Referencia',
  'Paciente',
  'Financiador',
  'Cod. Int. Financiador',
  'Operador',
  'Diagnostico',
  'Activo'
]

interface props {
  cases: { [key: string]: CASES },
  requestState: string,
  updateCases: any,
  setHeader: any,
  getCasesClose: any,
}

const MyCases = ({
  cases,
  requestState,
  updateCases,
  setHeader,
  getCasesClose }: props) => {

  const [flag, setFlag] = useState(false);
  const [loading, setLoading] = useState(true);
  const _case = cases;
  const history = useHistory();

  useEffect(() => {
    setHeader('Listado de Casos');
    if (!!cases)
      updateCases();
    else
      setLoading(false);
  }, []);

  const setCasesListScreen = () => {
    setFlag(false);
  }

  const ReturnButton = () => {
    return (
      <IconButton onClick={setCasesListScreen}>
        <ArrowBackIcon />
      </IconButton>
    );
  }

  const onRequestHandler = (searchText: String) => {

  }

  const handleCaseClick = (caseId: any) => {
    history.push(`/cases/${caseId}`);
  }

  return (
    <>
      <div>
        {/* <ListCasesCard/> */}
        <Card>
          <CardHeader
            style={{ textAlign: 'center' }}
            title={<Typography color='secondary' variant='h5'>
              Listado de Casos
              </Typography>}
          />
          <CardContent className={styles.classContent}>
            {/* <SearchBar
              onChange={(searchText: string) => setSearchText(searchText)}
              onRequestSearch={() => onRequestHandler(searchText)}
            /> */}
            {(requestState == 'REQUEST') ?
              <WaitingAnimation
                height={100}
                width={100}
              />
              :
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {
                          Headers.map((head, i) => {
                            return (
                              <TableCell key={i}>
                                <b>{head}</b>
                              </TableCell>
                            )
                          })
                        }
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* 
                          Mostrar Tablita con datos 
                          Hacer un .map() sobre el array
                      */
                        Object.keys(_case).map((key, i) => {
                          return (
                            <TableRow
                              key={i}
                              onClick={() => { handleCaseClick(_case[key].id) }}
                              className={styles.tableRow}
                            >
                              {/* Table cel * cant de datos */}
                              <TableCell>{_case[key]?.id}</TableCell>
                              <TableCell>{_case[key]?.patient?.name + ' ' + _case[key]?.patient?.surname}</TableCell>
                              <TableCell>{_case[key]?.financierId}</TableCell>
                              <TableCell>{_case[key]?.financierInternalCode}</TableCell>
                              <TableCell>{_case[key]?.operator?.name}</TableCell>
                              <TableCell>{_case[key]?.diagnoses[0]?.name}</TableCell>
                              <TableCell>{
                                (_case[key].active) ?
                                  <CheckCircleIcon color='primary' /> :
                                  <HighlightOffIcon style={{ backgroundColor: ' #f44336' }} />
                              }</TableCell>
                            </TableRow>
                          )
                        })
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            }
          </CardContent>
        </Card>
      </div>
    </>
  );
}

const mapDispatchToProps = {
  updateCases,
  setHeader,
  getCasesClose,
};

const mapStatesToProps = (state: any) => {
  const cases: { [key: string]: CASES } = state.cases;
  const requestState = requestStateSelector('GET_CASES_', state);
  return {
    cases,
    requestState,
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(MyCases);